import { useState, useEffect, createContext, useContext } from 'react';
import { useProfileContext } from 'context';
import { useGetOrgLocationsByUser } from 'hooks';

const LocationContext = createContext();

export function LocationProvider({ children }) {
  const { userProfile } = useProfileContext();

  const { data: locationsData, isSuccess: isLocationsSuccess } = useGetOrgLocationsByUser(userProfile.user_id);

  const [initialItems, setInitialItems] = useState([]);
  const [locationSelection, setLocationSelection] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    if (locationsData && locationsData.length > 0) {
      const initial_items = locationsData.map(location => ({ value: location.id, label: location.name }));
      const selected_items = locationsData.map(location => (location.id))
      setInitialItems(initial_items);
      setLocationSelection(selected_items); // Select all locations by default
      setAllLocations(selected_items);
    }
  }, [locationsData]);

  return (
    <LocationContext.Provider value={{ initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess, allLocations }}>
      {children}
    </LocationContext.Provider>
  );
}

export function useLocationContext() {
  return useContext(LocationContext);
}