import React, { useEffect, useState, lazy, Suspense, useRef, useCallback, use } from 'react';
import { Flex, Separator, Text, Button, SegmentedControl, Link, Tooltip, TextField, Tabs, CheckboxGroup,
    Callout
 } from '@radix-ui/themes';
import * as Form from "@radix-ui/react-form";
import { UploadIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { IPhoneMockup } from 'react-device-mockup';
import { debounce } from 'lodash';
import { useGetOrgGuestPortalSettings, useUpdateOrgGuestPortalSettings  } from 'hooks';
import { useToast } from '../../context/ToastContext';
import { ToastAlert } from "components";
import { useProfileContext } from "context";

const CaptivePortalMockup = lazy(() => import('./CaptivePortalMockup'));

const GuestPortal = ( ) => {
    const { userProfile } = useProfileContext();
    const organisationID = userProfile.organisation_id;

    const { setToastMessage } = useToast();
    const [view, setView] = useState('mobile');
    const [screenWidthMobile, setScreenWidthMobile] = useState(250); // Default screenWidth for IPhoneMockup
    const [scaleFactorMobile, setScaleFactorMobile] = useState(1); // Default scale factor for IPhoneMockup

    // Mobile view calculations for IPhoneMockup
    useEffect(() => {
        const handleResize = debounce(() => {
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;

            // Mobile view calculations
            const aspectRatio = 19.5 / 9;
            const maxDesiredWidth = 390; // The maximum desired screenWidth for IPhoneMockup
            const offset = 250;
            const availableHeight = windowHeight - offset;

            const calculatedScreenWidth = Math.min(
                maxDesiredWidth,
                availableHeight / aspectRatio,
                windowWidth // Ensure it doesn't exceed the viewport width
            );
            const calculatedScaleFactor = calculatedScreenWidth / maxDesiredWidth;

            setScreenWidthMobile(calculatedScreenWidth);
            setScaleFactorMobile(calculatedScaleFactor);
        }, 100);

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    },[]);

    console.log('GuestPortal.jsx: organisationID:', organisationID);
    const { data: guestPortalSettings } = useGetOrgGuestPortalSettings(organisationID);
    const { mutate: updateGuestPortalSettings } = useUpdateOrgGuestPortalSettings();

    const [ activeTab, setActiveTab ] = useState('portal')

    const [ emailFlag, setEmailFlag ] = useState(null);
    const [ ageFlag, setAgeFlag ] = useState(null);
    const [ genderFlag, setGenderFlag ] = useState(null);

    const handleCpFormFieldsChange = (values) => {
        setEmailFlag(true ? values.includes('email') : false);
        setAgeFlag(true ? values.includes('age') : false);
        setGenderFlag(true ? values.includes('gender') : false);
    };

    const [logoUpload, setLogoUpload] = useState(null); // Logo image upload
    const [logoPreview, setLogoPreview] = useState(null); // Logo image URL for preview
    const [logoFileName, setLogoFileName] = useState(''); // Logo image file name
    const logoInputRef = useRef(null);

    const [backgroundImageUpload, setBackgroundImageUpload] = useState(null); // Background image upload
    const [backgroundPreview, setBackgroundPreview] = useState(null); // Background image URL for preview
    const [backgroundFileName, setBackgroundFileName] = useState(''); // Background image file name
    const backgroundInputRef = useRef(null);

    function colorConversion(inputColour, opacity) {
        const alpha = Math.round(opacity * 2.55).toString(16).padStart(2, '0').toUpperCase();
        return `${inputColour}${alpha}`;
    }

    const [backgroundOverlayColor, setBackgroundOverlayColor] = useState('#000000'); // Default Background overlay color
    const [backgroundOverlayOpacity, setBackgroundOverlayOpacity] = useState(50); // Default Background overlay opacity

    useEffect(() => {
        colorConversion(backgroundOverlayColor, backgroundOverlayOpacity);
    }, [backgroundOverlayColor, backgroundOverlayOpacity]);

    const [buttonColor, setButtonColor] = useState('#3807D9');

    function getButtonTextColorBasedOnBackground(buttonBackgroundColor) {
        buttonBackgroundColor = buttonBackgroundColor.replace('#', '');
        // Converting the hex color to RGB
        const r = parseInt(buttonBackgroundColor.substring(0, 2), 16) / 255;
        const g = parseInt(buttonBackgroundColor.substring(2, 4), 16) / 255;
        const b = parseInt(buttonBackgroundColor.substring(4, 6), 16) / 255;
    
        // Calculating luminance using the formula
        const luminance = 0.2126 * (r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)) +
                          0.7152 * (g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)) +
                          0.0722 * (b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4));
    
        // If luminance is higher than 0.5, using black text; otherwise, white text
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    }

    const [buttonTextColor, setButtonTextColor] = useState(getButtonTextColorBasedOnBackground(buttonColor));

    useEffect(() => {
        setButtonTextColor(getButtonTextColorBasedOnBackground(buttonColor));
    }, [buttonColor]);

    const [deleteLogo, setDeleteLogo] = useState(false);
    const [deleteBackgroundImage, setDeleteBackgroundImage] = useState(false);

    const [ message, setMessage ] = useState('');
    const [ redirectUrl, setRedirectUrl ] = useState('');

    useEffect(() => {
        if (guestPortalSettings) {
            if (guestPortalSettings.data.logo) { setLogoPreview(guestPortalSettings.data.logo);}
            setLogoFileName(guestPortalSettings.data.logo_filename);
            
            if (guestPortalSettings.data.background_image){setBackgroundPreview(guestPortalSettings.data.background_image);}
            setBackgroundFileName(guestPortalSettings.data.background_image_filename);
            setBackgroundOverlayColor(guestPortalSettings.data.background_overlay_colour);
            setBackgroundOverlayOpacity(guestPortalSettings.data.background_overlay_opacity * 100);
            setButtonColor(guestPortalSettings.data.button_colour);
            setMessage(guestPortalSettings.data.message);
            setRedirectUrl(guestPortalSettings.data.redirect_url);
            setEmailFlag(guestPortalSettings.data.email_flag);
            setAgeFlag(guestPortalSettings.data.age_flag);
            setGenderFlag(guestPortalSettings.data.gender_flag);
        }
    }, [guestPortalSettings]);

    // Handling Logo image upload
    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoUpload(file); // Storing the logo image object
            setLogoFileName(file.name); // Storing the logo image file name

            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result); // Logo image URL for preview
            };
            reader.readAsDataURL(file);
        }
        setToastMessage('Uploaded');
        setDeleteLogo(false);
        handleInputChange(true);
    };

    const handleLogoClear = () => {
        setLogoUpload(null);
        setLogoPreview(null);
        setLogoFileName('');
        if (logoInputRef.current) {
            logoInputRef.current.value = '';
        }
        setDeleteLogo(true);
        handleInputChange(false);
    };

    // Triggering the hidden file input click for logo
    const triggerLogoInput = () => {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    };

    // Handling Background image upload
    const handleBackgroundImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBackgroundImageUpload(file);
            setBackgroundFileName(file.name);

            const reader = new FileReader();
            reader.onloadend = () => {
                setBackgroundPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setToastMessage('Uploaded');
        setDeleteBackgroundImage(false);
        handleInputChange(true);
    };

    const handleBackgroundImageClear = () => {
        setBackgroundImageUpload(null);
        setBackgroundPreview(null);
        setBackgroundFileName('');
        if (backgroundInputRef.current) {
            backgroundInputRef.current.value = '';
        }
        setDeleteBackgroundImage(true);
        handleInputChange(false);  
    };

    // Triggering the hidden file input click for background image
    const triggerBackgroundInput = () => {
        if (backgroundInputRef.current) {
            backgroundInputRef.current.click();
        }
    };

    const resetForm = () => {
        setLogoUpload(null);
        setLogoPreview(guestPortalSettings.data.logo);
        setLogoFileName(guestPortalSettings.data.logo_filename);
        if (logoInputRef.current) {
            logoInputRef.current.value = '';
        }
        setBackgroundImageUpload(null);
        setBackgroundPreview(guestPortalSettings.data.background_image);
        setBackgroundFileName(guestPortalSettings.data.background_image_filename);
        if (backgroundInputRef.current) {
            backgroundInputRef.current.value = '';
        }
        setBackgroundOverlayColor(guestPortalSettings.data.background_overlay_colour);
        setBackgroundOverlayOpacity(guestPortalSettings.data.background_overlay_opacity * 100);
        setButtonColor(guestPortalSettings.data.button_colour);
        setButtonTextColor(getButtonTextColorBasedOnBackground(guestPortalSettings.data.button_colour));
        setDeleteLogo(false);
        setDeleteBackgroundImage(false);
        setMessage(guestPortalSettings.data.message);
        setRedirectUrl(guestPortalSettings.data.redirect_url);
        setEmailFlag(guestPortalSettings.data.email_flag);
        setAgeFlag(guestPortalSettings.data.age_flag);
        setGenderFlag(guestPortalSettings.data.gender_flag);
        handleInputChange(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData();
        
        if (urlError) {
            setToastMessage('Please enter a valid Redirection URL');
            return;
        }

        if (logoUpload) {data.append('logo', logoUpload);}
        if (backgroundImageUpload) {data.append('background_image', backgroundImageUpload);}
        if (backgroundOverlayColor !== guestPortalSettings.data.background_overlay_colour) {
            data.append('background_overlay_colour', backgroundOverlayColor);
        }
        if (backgroundOverlayOpacity !== guestPortalSettings.data.background_overlay_opacity) {
            data.append('background_overlay_opacity', backgroundOverlayOpacity/100);
        }
        if (buttonColor !== guestPortalSettings.data.button_colour) {
            data.append('button_colour', buttonColor);
        }
        if (buttonTextColor !== guestPortalSettings.data.button_text_colour) {
            data.append('button_text_colour', buttonTextColor);
        }
        if (deleteLogo) {data.append('delete_logo', true);}
        if (deleteBackgroundImage) {data.append('delete_background_image', true);}
        if (message !== guestPortalSettings.data.message) {data.append('message', message);}
        if (redirectUrl !== guestPortalSettings.data.redirect_url) {data.append('redirect_url', redirectUrl);}
        if (emailFlag !== guestPortalSettings.data.email_flag) {data.append('email_flag', emailFlag);}
        if (ageFlag !== guestPortalSettings.data.age_flag) {data.append('age_flag', ageFlag);}
        if (genderFlag !== guestPortalSettings.data.gender_flag) {data.append('gender_flag', genderFlag);}

        updateGuestPortalSettings({ orgID: organisationID, data });
        resetForm();
    };

    const [isDirty, setIsDirty] = useState(false);

    const handleInputChange = (value) => {
        setIsDirty(value);
    };

    const handleInputMessageChange = (value) => {
        setMessage(value);
        handleInputChange(true);
    };

    const handleInputRedirectUrlChange = (value) => {
        setRedirectUrl(value);
        handleInputChange(true);

        const validation = validateUrl(value);
        if (validation === true) {
            setUrlError('');      // No error
        } else {
            setUrlError(validation); // e.g. "URL must start with https://"
        }
    }

    // Redirection URL validation error
    const [urlError, setUrlError] = useState('');
    const validateUrl = (url) => {
        if (!url) return true; // Empty URL is valid
        if (!url.startsWith('https://')) {
            return 'URL must start with https://';
        }
        try {
            new URL(url);
            return true;
        } catch (e) {
            return 'Please enter a valid URL';
        }
    };

  return (
    <React.Fragment>
        {/* Guest Portal */}
        <Flex gap="7" style={{alignSelf: 'stretch', width: '100%', height: '100%', alignItems: 'center', flex: '1 0 0'}}>
            {/* Mockup Preview Section */}
            <Flex gap="5" direction="column" style={{alignItems: 'center', alignSelf: 'stretch', flex: '1 0 0'}}>
                <SegmentedControl.Root size="1" variant='surface' defaultValue="mobile" onValueChange={setView}>
                    <SegmentedControl.Item value="mobile" style={{cursor: 'pointer'}}>Mobile</SegmentedControl.Item>
                    <SegmentedControl.Item value="desktop" style={{cursor: 'pointer'}}>Desktop</SegmentedControl.Item>
                </SegmentedControl.Root>
                {/* Mobile / Desktop */}
                {view === 'mobile' ? (
                    <IPhoneMockup screenWidth={screenWidthMobile} frameColor='var(--gray-12)' 
                        statusbarColor='var(--gray-contrast)' transparentNavBar='true' hideStatusBar='true' 
                        screenType='notch'>
                        <Suspense>
                            <CaptivePortalMockup 
                                device={view} 
                                scaleFactor={scaleFactorMobile}
                                logoPreview={logoPreview} 
                                backgroundPreview={backgroundPreview}
                                backgroundOverlayColor={colorConversion(backgroundOverlayColor, backgroundOverlayOpacity)}
                                buttonColor={buttonColor}
                                buttonTextColor={buttonTextColor}
                                email={emailFlag}
                                age={ageFlag}
                                gender={genderFlag}
                                message={message}
                                activeTab={activeTab}
                            />
                        </Suspense>
                    </IPhoneMockup>
                ) : (
                <Suspense>
                    <Flex style={{flex: '1 0 0', alignSelf: 'stretch', justifyContent: 'center'}}>
                        <CaptivePortalMockup 
                            device={view}
                            logoPreview={logoPreview} 
                            backgroundPreview={backgroundPreview}
                            backgroundOverlayColor={colorConversion(backgroundOverlayColor, backgroundOverlayOpacity)}
                            buttonColor={buttonColor}
                            buttonTextColor={buttonTextColor}
                            email={emailFlag}
                            age={ageFlag}
                            gender={genderFlag}
                            message={message}
                            activeTab={activeTab}
                        />
                    </Flex>
                </Suspense>
                )}
            </Flex>
            {/* Settings Section */}
            <Flex style={{alignItems: 'flex-start', alignSelf: 'stretch'}} height="100%">
                <Form.Root onSubmit={handleSubmit} style={{height: '100%', width: '100%'}}>
                    <Flex justify="between" width="296px" direction="column" style={{alignItems: 'flex-start',
                        alignSelf: 'stretch', flex: '1 0 0', borderRadius: 'var(--radius-4)', height: '100%',
                        border: '1px solid var(--gray-a6)', backgroundColor: 'var(--color-panel-solid)' }}>
                        <Flex direction="column" style={{alignSelf: 'stretch', overflow: 'scroll', flex: '1 0 0'}}>
                            {/* Settings Header */}
                            <Tabs.Root defaultValue='portal' onValueChange={setActiveTab}>
                                <Tabs.List style={{padding: 'var(--space-2) var(--space-4) 0px var(--space-4)', 
                                    position: 'sticky', top: '0', zIndex: '1', backgroundColor: 'var(--color-panel-solid)',
                                    borderTopLeftRadius: 'var(--radius-4)', borderTopRightRadius: 'var(--radius-4)'}}>
                                    <Tabs.Trigger value="portal" style={{cursor: 'pointer'}}>Portal</Tabs.Trigger>
                                    <Tabs.Trigger value="landing" style={{cursor: 'pointer'}}>Landing</Tabs.Trigger>
                                </Tabs.List>
                                {/* Portal Tab */}
                                <Tabs.Content value="portal">
                                    <Flex p="4" gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Text size="1" weight="regular" color="gray">
                                            Your portal will appear on your guests' device when they connect to your 
                                            guest Wi-Fi for the first time.
                                        </Text>
                                    </Flex>
                                    {/* Email, Age and Gender Selects */}
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    <Flex p="4" gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                    <CheckboxGroup.Root 
                                        value={[emailFlag ? 'email' : '', ageFlag ? 'age' : '', genderFlag ? 'gender': '' ]}
                                        onValueChange={handleCpFormFieldsChange}>
                                        <CheckboxGroup.Item value="email">Email</CheckboxGroup.Item>
                                        <CheckboxGroup.Item value="age">Age</CheckboxGroup.Item>
                                        <CheckboxGroup.Item value="gender">Gender</CheckboxGroup.Item>
                                    </CheckboxGroup.Root>
                                    </Flex>
                                    {/* Logo Input Field */}
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    <Flex gap="1" p="4" direction="column" style={{alignSelf: 'stretch'}}>
                                        <Form.Field name="logo_image">
                                            <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                <Text size="2" weight="medium">Logo</Text>
                                            </Form.Label>
                                            <div>
                                                <Flex gap="2">
                                                    <input type="file" style={{ display: 'none' }} ref={logoInputRef}
                                                        onChange={handleLogoUpload} accept="image/*" />
                                                    <Button type="button" size="2" variant="surface" color="gray"
                                                        style={{display: 'flex', padding: '0px var(--space-2)', 
                                                        cursor: 'pointer', justifyContent: 'center', flex: '1'}}
                                                        onClick={triggerLogoInput}>
                                                        {logoPreview ? (
                                                            <Tooltip content="Upload and replace">
                                                                <Text>
                                                                    {logoFileName?.length >= 18 ? `${logoFileName.slice(0, 18)}...` : logoFileName}
                                                                </Text>
                                                            </Tooltip>
                                                        ) : (
                                                            <>
                                                                <UploadIcon />
                                                                <Text>Upload</Text>
                                                            </>
                                                        )}
                                                    </Button>
                                                    {logoPreview && 
                                                    <Button type="button" size="2" variant="surface" color="red" 
                                                    style={{cursor: 'pointer'}} onClick={() => handleLogoClear()}>
                                                        Delete
                                                    </Button>}
                                                </Flex>
                                            </div>
                                        </Form.Field>
                                        <Text size="1" weight="regular" color="gray">Recommended size of 200px by 96px.</Text>
                                    </Flex>
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    {/* Background Image & Background Overlay */}
                                    <Flex direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Flex gap="4" p="4" direction="column" style={{alignSelf: 'stretch'}}>
                                            {/* Background Image */}
                                            <Flex gap="1" direction="column" style={{alignSelf: 'stretch'}}>
                                                <Form.Field name="background_image">
                                                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                        <Text size="2" weight="medium">Background image</Text>
                                                    </Form.Label>
                                                    <div>
                                                        <Flex gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                                            <input type="file" style={{ display: 'none' }} ref={backgroundInputRef}
                                                            onChange={handleBackgroundImageUpload} id="background-upload" 
                                                            accept="image/*"
                                                            name="background_image" />
                                                            <Button type="button" size="2" variant="surface" color="gray"
                                                                style={{display: 'flex', padding: '0px var(--space-2)', 
                                                                cursor: 'pointer', justifyContent: 'center', flex: '1'}}
                                                                onClick={triggerBackgroundInput}>
                                                                {backgroundPreview ? (
                                                                    <Tooltip content="Upload and replace">
                                                                        <Text>
                                                                            {backgroundFileName?.length >= 18 ? `${backgroundFileName.slice(0, 18)}...` : backgroundFileName}
                                                                        </Text>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <>
                                                                        <UploadIcon />
                                                                        <Text>Upload</Text>
                                                                    </>
                                                                )}
                                                            </Button>
                                                            {backgroundPreview &&
                                                            <Button type="button" size="2" variant="surface" color="red" 
                                                            style={{cursor: 'pointer'}} 
                                                            onClick={() => handleBackgroundImageClear()}>
                                                                Delete
                                                            </Button>}
                                                        </Flex>
                                                    </div>
                                                </Form.Field>
                                                <Text size="1" weight="regular" color="gray">Recommended size of 900px by 600px.</Text>
                                            </Flex>
                                            {/* Background Overlay */}
                                            <Flex gap="1" direction="column" style={{alignSelf: 'stretch'}}>
                                                <Form.Field name="background_overlay">
                                                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                        <Text size="2" weight="medium">Background overlay</Text>
                                                    </Form.Label>
                                                    <Form.Control asChild>
                                                        <input type="hidden" name="background_overlay" value={backgroundOverlayColor}/>
                                                    </Form.Control>
                                                    <Flex gap="2" style={{alignSelf: 'stretch', alignItems: 'flex-start'}}>
                                                        <label htmlFor="background_overlay_colour_picker" 
                                                            style={{position: 'relative', display: 'inline-block', width: '100%',
                                                            cursor: 'pointer'}}>
                                                            <input  type="color" id="background_overlay_colour_picker"
                                                            value= {backgroundOverlayColor}
                                                            onChange={(e) => {
                                                                setBackgroundOverlayColor(e.target.value);
                                                                handleInputChange(true); // Add this
                                                            }}
                                                            style={{ opacity: 0, position: 'absolute', width: '100%',
                                                                height: '100%', border: 'none', padding: 0, margin: 0,
                                                                cursor: 'pointer'}}/>
                                                            {/* Custom color display */}
                                                            <div style={{ width: '100%', height: '32px', display: 'flex',
                                                                backgroundColor: colorConversion(backgroundOverlayColor, backgroundOverlayOpacity),
                                                                borderRadius: 'var(--radius-2)',
                                                                border: '1px solid var(--gray-a6)'}}>
                                                            </div>
                                                        </label>
                                                        <TextField.Root size="2"
                                                            type = "number"
                                                            min={0} max={100} step={1}
                                                            value={backgroundOverlayOpacity}
                                                            onChange={(e) => {
                                                                setBackgroundOverlayOpacity(e.target.value);
                                                                handleInputChange(true);
                                                            }}
                                                            style={{textAlign: 'center', width: '70px'}}>
                                                            <TextField.Slot side="right" style={{padding: '4px' }}>
                                                                %
                                                            </TextField.Slot>
                                                        </TextField.Root>
                                                    </Flex>
                                                    <Text size="1" weight="regular" color="gray">Recommended for logo visibility.</Text>
                                                </Form.Field>
                                            </Flex>
                                        </Flex>
                                        <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                        {/* Button Colour Input Field */}
                                        <Flex gap="1" p="4" direction="column" style={{alignSelf: 'stretch'}}>
                                            <Form.Field name="button_color">
                                                <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                    <Text size="2" weight="medium">Button colour</Text>
                                                </Form.Label>
                                                <Form.Control asChild>
                                                    <input type="hidden" name="button_color" value={buttonColor} />
                                                </Form.Control>
                                                <Flex direction="column" style={{alignSelf: 'stretch'}}>
                                                    <label htmlFor="button_colour_picker" style={{position: 'relative',
                                                        display: 'inline-block',width: '100%',cursor: 'pointer'}}>
                                                        <input type="color" id="button_colour_picker"
                                                            value={buttonColor}
                                                            onChange={(e) => {
                                                                setButtonColor(e.target.value);
                                                                handleInputChange(true);
                                                            }}
                                                            style={{ opacity: 0, position: 'absolute', width: '100%',
                                                            height: '100%', border: 'none', padding: 0, margin: 0, cursor: 'pointer'}}/>
                                                        <div style={{ width: '100%', height: '32px',
                                                            backgroundColor: buttonColor, cursor: 'pointer',
                                                            borderRadius: 'var(--radius-2)',
                                                            border: '1px solid var(--gray-a6)'}}>
                                                        </div>
                                                    </label>      
                                                </Flex>
                                            </Form.Field>
                                            <Text size="1" weight="regular" color="gray">
                                                Recommended contrast of 3:1, against white. Visit &nbsp;
                                                <Link color="blue" underline='always' href='https://webaim.org/resources/contrastchecker/'>
                                                    WebAim's Contrast Checker
                                                </Link> 
                                                &nbsp;to test your button colour.
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    {/* Custom Message */}
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    <Flex p="4" gap="1" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Text size="2" weight="medium">Message</Text>
                                        <TextField.Root size="2"
                                            value={message}
                                            onChange={(e) => handleInputMessageChange(e.target.value)}
                                            placeholder='Welcome Message (optional)'
                                            maxLength={48}
                                            style={{alignSelf: 'stretch'}}/>
                                    </Flex>
                                </Tabs.Content>
                                {/* Landing Tab */}
                                <Tabs.Content value="landing">
                                    {/* Header */}
                                    <Flex p="4" gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Text size="1" weight="regular" color="gray">
                                            Your landing will appear after a guest has been connected to the internet, 
                                            on iOS and MacOS devices only.
                                        </Text>
                                        <Callout.Root size="1" variant="soft" color="gray">
                                            <Callout.Icon>
                                                <InfoCircledIcon/>
                                            </Callout.Icon>
                                            <Callout.Text style={{fontSize: '12px'}}>
                                                Your landing will appear after a guest has been connected to the internet, 
                                                on iOS and MacOS devices only.
                                            </Callout.Text>
                                        </Callout.Root>
                                    </Flex>
                                    {/* Landing Page URL */}
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    <Flex p="4" gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                            <Text size="2" weight="medium">Redirection URL</Text>
                                            </Flex>
                                        </Text>
                                        <TextField.Root size="2"
                                            value={redirectUrl}
                                            onChange={(e) => handleInputRedirectUrlChange(e.target.value)}
                                            placeholder='https://example.com'
                                            color={urlError ? 'red' : 'inherit'}
                                            style={{alignSelf: 'stretch'}}/>
                                        {urlError && (
                                            <Text size="1" weight="regular" color="red">
                                                {urlError}
                                            </Text>
                                        )}
                                    </Flex>
                                </Tabs.Content>
                            </Tabs.Root>
                        </Flex>
                        {/* Save Changes Button */}
                        <Flex direction="column" style={{alignSelf: 'stretch', alignItems: 'flex-end'}}>
                            <Separator size="4" orientation="horizontal" />
                            <Flex p="4" direction="column" style={{alignSelf: 'stretch', alignItems: 'flex-start'}}>
                                <Button type="submit" variant="outline" style={{alignSelf: 'stretch', cursor: 'pointer', 
                                    width: '100%'}} 
                                    disabled={!logoUpload && !backgroundImageUpload && 
                                    (backgroundOverlayColor === guestPortalSettings?.data.background_overlay_colour) &&
                                    (backgroundOverlayOpacity === guestPortalSettings?.data.background_overlay_opacity * 100) &&
                                    (buttonColor === guestPortalSettings?.data.button_colour) &&
                                    (!deleteLogo) && (!deleteBackgroundImage) &&
                                    (message === guestPortalSettings?.data.message) &&
                                    (redirectUrl === guestPortalSettings?.data.redirect_url) &&
                                    (emailFlag === guestPortalSettings?.data.email_flag) &&
                                    (ageFlag === guestPortalSettings?.data.age_flag) &&
                                    (genderFlag === guestPortalSettings?.data.gender_flag)}>
                                    Save Changes
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Form.Root>
            </Flex>
        </Flex>
        {/* Toast Updates */}
        <ToastAlert/>
    </React.Fragment>
  );
}

export default GuestPortal;
