import React, { useState, useRef, useEffect } from "react";
import { Text, Flex, Button, DropdownMenu } from "@radix-ui/themes";
import { LocationMultiSelect } from "components";
import { useGetCustomers, useGetActiveIntegrations } from "hooks";
import { useLocationContext, useProfileContext } from "context";
import { ChevronDownIcon, CaretSortIcon, CaretUpIcon, CaretDownIcon } from "@radix-ui/react-icons";
import { MultiSelect } from "components/multiSelect/MultiSelect";
import { Table, Card } from "@radix-ui/themes";
import NoResults from "./NoResults";
import { attemptGetCustomers } from "api/UsersApi";
import { useNavigate } from 'react-router-dom';

export default function Customers() {

    const { initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess, allLocations } = useLocationContext();

    const { userProfile } = useProfileContext();
    const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile?.organisation_id);
  
    const isMailchimpActive = () => {
      if (!activeIntegrationsData?.data?.integrations) return false;
      return activeIntegrationsData.data.integrations.some(
        integration => integration.name === "mailchimp"
      );
    };

    // Define the options
    const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "others" },
    { label: "No Response", value: "prefer not to respond" }
    ];

    const ageOptions = [
    { label: "Under 18", value: "Under 18" },
    { label: "18-24", value: "18-24" },
    { label: "25-34", value: "25-34" },
    { label: "35-44", value: "35-44" },
    { label: "45-54", value: "45-54" },
    { label: "55-64", value: "55-64" },
    { label: "65+", value: "65+" },
    { label: "No Response", value: "prefer not to respond" }
    ];

    const navigate = useNavigate();

    // Initialize selections with all options
    const [ageSelection, setAgeSelection] = useState(ageOptions.map(option => option.value));
    const [genderSelection, setGenderSelection] = useState(genderOptions.map(option => option.value));

    // Create filters object when any selection changes
    const filters = React.useMemo(() => ({
        gender: genderSelection,
        age: ageSelection,
        location_ids: locationSelection
    }), [genderSelection, ageSelection, locationSelection]);


    const { 
        data: customerData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading, 
        error 
    } = useGetCustomers(filters, false);

    

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
      
        // Format time
        const timeString = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        }).toLowerCase();
      
        // Check if date is today
        if (date.toDateString() === now.toDateString()) {
          return `Today at ${timeString}`;
        }
      
        // Check if date is yesterday
        if (date.toDateString() === yesterday.toDateString()) {
          return `Yesterday at ${timeString}`;
        }
      
        // Format older dates
        const formattedDate = date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      
        return `${formattedDate} at ${timeString}`;
      };

    // Create an all-inclusive filter for the export all functionality
    const allFilters = React.useMemo(() => ({
        gender: genderOptions.map(option => option.value),
        age: ageOptions.map(option => option.value),
        location_ids: allLocations
    }), [genderOptions, ageOptions, allLocations]);
    
    // Additional hook instance for all customers
    const { data: allCustomers, isLoading: isLoadingAll } = useGetCustomers(allFilters);

    const downloadCSV = (data, filename) => {
        const headers = ['Email', 'Age', 'Gender', 'Created', 'Last Seen'];
        const csvData = data.map(customer => [
        customer.email_address,
        customer.age,
        customer.gender,
        new Date(customer.created).toLocaleString(),
        new Date(customer.last_seen).toLocaleString()
        ]);

        const csvContent = [
        headers.join(','),
        ...csvData.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [isExporting, setIsExporting] = useState(false);
    const [exportProgress, setExportProgress] = useState(0);
    
    const fetchAllPages = async (filtersToUse) => {
        let allCustomers = [];
        let currentPage = 1;
        let hasMore = true;
        
        while (hasMore) {
            // Use the hook function but await its result
            const response = await attemptGetCustomers({
                ...filtersToUse,
                page: currentPage
            });
            
            const { data, has_more, total_count } = response.data;
            allCustomers = [...allCustomers, ...data];
            hasMore = has_more;
            
            // Calculate and update progress
            const progress = Math.round((allCustomers.length / total_count) * 100);
            setExportProgress(progress);
            
            currentPage++;
        }
        
        return allCustomers;
    };
    
    const handleExportAll = async () => {
        try {
            setIsExporting(true);
            setExportProgress(0);
            
            const allData = await fetchAllPages(allFilters);
            downloadCSV(allData, 'all_customers.csv');
            
        } catch (error) {
            console.error('Export failed:', error);
        } finally {
            setIsExporting(false);
            setExportProgress(0);
        }
    };
    
    const handleExportFiltered = async () => {
        try {
            setIsExporting(true);
            setExportProgress(0);
            
            const filteredData = await fetchAllPages(filters);
            downloadCSV(filteredData, 'filtered_customers.csv');
            
        } catch (error) {
            console.error('Export failed:', error);
        } finally {
            setIsExporting(false);
            setExportProgress(0);
        }
    };

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);

    
    const sortData = (data, column, direction) => {
      // Return empty array if no data or data is not an array
      if (!data || !Array.isArray(data)) return [];
      
      // Return original data if no sorting needed
      if (!column || !direction) return data;
    
      return [...data].sort((a, b) => {
        if (column === 'age') {
          const ageOrder = ageOptions.map(opt => opt.value);
          const indexA = ageOrder.indexOf(a.age);
          const indexB = ageOrder.indexOf(b.age);
          return direction === 'asc' ? indexA - indexB : indexB - indexA;
        }
    
        if (column === 'created' || column === 'last_seen') {
          return direction === 'asc' 
            ? new Date(a[column]) - new Date(b[column])
            : new Date(b[column]) - new Date(a[column]);
        }
    
        // For email and gender (alphabetical sorting)
        return direction === 'asc'
          ? (a[column] || '').localeCompare(b[column] || '')
          : (b[column] || '').localeCompare(a[column] || '');
      });
    };

    
    const handleHeaderClick = (column) => {
      setSortColumn(oldColumn => {
        if (oldColumn !== column) return column;
        return column;
      });
      
      setSortDirection(oldDirection => {
        if (!oldDirection) return 'desc';
        if (oldDirection === 'desc') return 'asc';
        return null;
      });
    };

    const customers = React.useMemo(() => {
        if (!customerData?.pages) return [];
        // Flatten all pages into a single array
        return customerData.pages.flatMap(page => page.data);
    }, [customerData?.pages]);
    console.log(customers);

    const bottomRef = useRef(null);

    // Add this effect for intersection observer
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
                    fetchNextPage();
                }
            },
            { threshold: 0.1 }
        );

        if (bottomRef.current) {
            observer.observe(bottomRef.current);
        }

        return () => {
            if (bottomRef.current) {
                observer.unobserve(bottomRef.current);
            }
        };
    }, [bottomRef, fetchNextPage, hasNextPage, isFetchingNextPage]);

    console.log(customers);
                            
  return (
    <Flex className="content-container" direction="column" gap="5" height="100%" style={{alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch'}}>
        <Flex p="8" direction="column" gap="8" height="100%" style={{alignSelf: "stretch", alignItems: 'flex-start', flex: '1 0 0'}}>
            {/* Customers Page Header Section */}
            <Flex className="header-container" style={{alignSelf: "stretch"}} justify="center" align="end">
                <Flex className="date-greeting-container" justify="between" style={{flex: '1 0 0',
                    alignItems: 'center', alignSelf: 'stretch'}}>
                    <Text size="8" weight="bold">Customers</Text>
                    <Flex display="flex" height="var(--space-6)" p="3" justify="center" align="center" gap="4">
                        {/* Contact Support Button */}
                        <Button size="2" variant="ghost" color="gray" onClick={() => {
                            const supportEmail = 'support@b-connect.co.uk';
                            const subject = 'Support Request';
                            const body = 'Hello, I need help with...';
                            const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                            window.location.href = mailtoLink;}} 
                            style={{cursor:"pointer"}}>
                            Contact support
                        </Button>
                        {/* Export Customers Dropdown */}
                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger>
                                <Button size="2" variant="ghost" color="gray">
                                Export customers <ChevronDownIcon width="16" height="16"/>
                                </Button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                                <DropdownMenu.Item 
                                style={{cursor: "pointer"}} 
                                onClick={handleExportAll}
                                >
                                Export all
                                </DropdownMenu.Item>
                                <DropdownMenu.Item 
                                style={{cursor: "pointer"}} 
                                onClick={handleExportFiltered}
                                >
                                Export current filter
                                </DropdownMenu.Item>
                                    {!isMailchimpActive() && (
                                        <>
                                        <DropdownMenu.Separator />
                                        <DropdownMenu.Item 
                                            style={{cursor: "pointer"}} 
                                            onClick={() => navigate('/integrations/browse-all')}
                                        >
                                            Integrate Mailchimp
                                        </DropdownMenu.Item>
                                        </>
                                    )}
                            </DropdownMenu.Content>
                        </DropdownMenu.Root>
                    </Flex>
                </Flex>
            </Flex>
            {/* Customers Page Data Section */}
            <Flex direction="column" gap="5" style={{alignItems: 'flex-start', alignSelf: 'stretch', flex: '1 0 0'}}>
                {/* Age, Gender and All Locations Filters */}
                <Flex className="filter-bar" style={{alignSelf: "stretch"}} justify="end" align="center" gap="2">
                    {/* Age Filter */}
                    <MultiSelect
                        options={ageOptions}
                        defaultValue={ageOptions.map(option => option.value)}
                        onValueChange={(value) => {
                        setAgeSelection(value);
                        }}
                        placeholder="Age"
                    />
                    {/* Gender Filter */}
                    <MultiSelect
                        options={genderOptions}
                        defaultValue={genderOptions.map(option => option.value)}
                        onValueChange={(value) => {
                        setGenderSelection(value);
                        }}
                        placeholder="Gender"
                    />
                    {/* Location Filter */}
                    <LocationMultiSelect
                        options={initialItems}
                        defaultValue={locationSelection}
                        onValueChange={(value) => {
                            setLocationSelection(value);
                        }}
                    />
                </Flex>
                {/* Customers Table */}
                {!isLoading && customers?.length === 0 ? (
                    <Flex pt="6" width="100%"> {/* Radix UI uses increments of 4, so pt="6" = 24px */}
                        <NoResults />
                    </Flex>
                    ) : (
                    <Card p="4" gap="4" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', 
                        alignSelf: 'stretch', overflow: 'scroll', flex: '1 0 0', height: "100%"}}>
                        <Table.Root style={{display: "flex", flexDirection: "column", alignItems: "flex-start", 
                            alignSelf: "stretch", height:"100%"}}>
                            {/* Table Header */}
                            
                            <Table.Header style={{  position:"sticky", top: "0", zIndex: "1", backgroundColor: 'var(--color-panel-solid)'}}>
                                <Table.Row align="center">
                                    <Table.ColumnHeaderCell style={{cursor: "pointer", width: "20%", position:"sticky", top: "0", zIndex: "10"}} 
                                    onClick={() => handleHeaderClick('email_address')}>
                                        <Flex align="center" gap="1">
                                            <Text size="2" weight="medium">Customer</Text>
                                            {sortColumn === 'email_address' && (
                                                sortDirection === 'asc' ? <CaretUpIcon /> : 
                                                sortDirection === 'desc' ? <CaretDownIcon /> : 
                                                <CaretSortIcon />
                                            )}
                                        </Flex>
                                    </Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell style={{cursor: "pointer", width: "20%"}}
                                        onClick={() => handleHeaderClick('age')}>
                                        <Flex align="center" gap="1">
                                            <Text size="2" weight="medium">Age</Text>
                                            {sortColumn === 'age' && (
                                                sortDirection === 'asc' ? <CaretUpIcon /> : 
                                                sortDirection === 'desc' ? <CaretDownIcon /> : 
                                                <CaretSortIcon />
                                            )}
                                        </Flex>
                                    </Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell style={{cursor: "pointer", width: "20%"}}
                                        onClick={() => handleHeaderClick('gender')}>
                                        <Flex align="center" gap="1">
                                            <Text size="2" weight="medium">Gender</Text>
                                            {sortColumn === 'gender' && (
                                                sortDirection === 'asc' ? <CaretUpIcon /> : 
                                                sortDirection === 'desc' ? <CaretDownIcon /> : 
                                                <CaretSortIcon />
                                            )}
                                        </Flex>
                                    </Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell style={{cursor: "pointer", width: "20%"}}
                                        onClick={() => handleHeaderClick('created')}>
                                        <Flex align="center" gap="1">
                                            <Text size="2" weight="medium">Created</Text>
                                            {sortColumn === 'created' && (
                                                sortDirection === 'asc' ? <CaretUpIcon /> : 
                                                sortDirection === 'desc' ? <CaretDownIcon /> : 
                                                <CaretSortIcon />
                                            )}
                                        </Flex>
                                    </Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell style={{cursor: "pointer", width: "20%"}}
                                        onClick={() => handleHeaderClick('last_seen')}>
                                        <Flex align="center" gap="1">
                                            <Text size="2" weight="medium">Last seen</Text>
                                            {sortColumn === 'last_seen' && (
                                                sortDirection === 'asc' ? <CaretUpIcon /> : 
                                                sortDirection === 'desc' ? <CaretDownIcon /> : 
                                                <CaretSortIcon />
                                            )}
                                        </Flex>
                                    </Table.ColumnHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            {/* Table Body */}
                            {/* className="overflow-auto flex-1 min-h-0" */}
                            <Table.Body>
                                {!isLoading && sortData(customers, sortColumn, sortDirection)?.map((customer, index) => (
                                    <Table.Row key={index} align="center">
                                        <Table.Cell>
                                            <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                                                {customer.email_address}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                                                {customer.age}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                                                {customer.gender}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                                                {formatDate(customer.created)}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                                                {formatDate(customer.last_seen)}
                                            </Text>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
            
                    {/* Infinite scroll trigger element */}
                    <div ref={bottomRef} style={{ height: "20px" }} />
                    
                    {/* Loading indicator for next page
                    {isFetchingNextPage && (
                        <Flex justify="center" align="center" p="5" width="100%">
                            <Text>Loading more...</Text>
                        </Flex>
                    )} */}
                </Table.Body>
                        </Table.Root>
                        
                        {/* Loading State */}
                        {/* {isLoading && (
                            <Flex justify="center" align="center" p="5"width="100%">
                                <Text>Loading...</Text>
                            </Flex>
                        )} */}
                        
                        {/* Error State */}
                        {/* {error && (
                            <Flex justify="center" align="center" p="5" width="100%" >
                                <Text color="red">Error loading customers</Text>
                            </Flex>
                        )} */}
                    </Card>
                )}
            </Flex>
        </Flex>
    </Flex>
  );
}
