import React, { createContext, useContext, useState } from 'react';
import { Flex, Card, Text, Badge, IconButton, Separator, Select, Button, DropdownMenu } from '@radix-ui/themes';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import { CaretUpIcon, CaretDownIcon, ArrowRightIcon } from '@radix-ui/react-icons';

const ActiveIntegrateCardContext = createContext(null);

function Root({ name, subtitle, image_url, option, children }) {

  // For the image_url argument, make sure your image is inside the src/assets/images folder!
  // Specify the file extension of the image in the image_url argument.
  // This argument is required!!
  
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  }

  return (
    <ActiveIntegrateCardContext.Provider value={{ showDropdown, handleShowDropdown }}>
      <Card style={{display: "flex", padding: "var(--space-4)", flexDirection: "column", alignItems: "flex-start", gap: "16px", alignSelf: "stretch"}}>
        <Flex className="frame-528173404" style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
          <Flex className="frame-528173423" display="flex" align="center" gap="4">
            <Flex className="frame-528173424" style={{borderRadius: "var(--space-1)", background: "var(--gray-12)"}} display="flex" height="40px" p="2" justify="center" align="center" gap="2">
              <img src={require(`../../assets/images/${image_url}`)} alt="icon" style={{width: "24px", height: "24px", borderRadius: "var(--space-1)", background: "lightgray 50% / cover no-repeat"}} />
            </Flex>
            <Flex className="frame-528173402" display="flex" direction="column" justify="center" align="start" gap="1">
              <Text size="4" weight="bold">
                {name}
              </Text>
              <Text style={{color: "var(--gray-a11)"}} size="1" weight="regular">
                {subtitle}
              </Text>
            </Flex>
          </Flex>
          <Flex className="frame-528173422" display="flex" align="center" gap="5">
            <Text style={{color: "var(--gray-a11)"}} size="1" weight="regular">
              All locations
            </Text>
            <Badge size="1" variant="soft" color="green">
              Active
            </Badge>
            <Flex className="frame-528173409" display="flex" align="center" gap="4">
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <Flex display="flex" width="var(--space-5)" height="var(--space-5)" justify="center" align="center">
                    <IconButton style={{cursor: "pointer"}} size="1" variant="ghost" color="gray">
                      <EllipsisHorizontalIcon width="16" height="16" />
                    </IconButton>
                  </Flex>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item style={{cursor: "pointer"}} onClick={option.onClick} color={option.color}>
                    {option.text}
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
              <Flex display="flex" width="var(--space-5)" height="var(--space-5)" justify="center" align="center">
                <IconButton style={{cursor: "pointer"}} onClick={handleShowDropdown} size="1" variant="ghost" color="gray">
                  {showDropdown ? <CaretDownIcon width="16" height="16" /> : <CaretUpIcon width="16" height="16" />}
                </IconButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {showDropdown ? children : null}
      </Card>
    </ActiveIntegrateCardContext.Provider>
  );
}

function Section({ name, subtitle, children }) {
  return (
    <React.Fragment>
      <Separator orientation="horizontal" size="4"/>
      <Flex className="frame-528173428" style={{alignSelf: "stretch"}} display="flex" align="start" gap="5">
        <Flex className="frame-528173428" style={{alignSelf: "stretch"}} display="flex" width="296px" direction="column" align="start" gap="2">
          <Text size="2" weight="medium">
            {name}
          </Text>
          <Text size="1" weight="regular">
            {subtitle}
          </Text>
        </Flex>
        {children}
      </Flex>
    </React.Fragment>
  );
}

export const ActiveIntegrateCard = {
  Root,
  Section
};