import { useState, useEffect } from 'react';
import { BrowseIntegrateCard, ActivatedIntegrationCard } from 'components';
import { Flex, Text, Select, IconButton, Separator, TextField, Button, Card } from '@radix-ui/themes';
import { 
  useAuthenticateIntegrations, 
  useGetMailchimpAudiences, 
  useCreateMailchimpFields, 
  useGetMailchimpContactFields ,
  useMapFieldsInMailchimp,
  useSyncGuestDataWithMailchimp,
  useGetActiveIntegrations
} from 'hooks';
import { useQueryClient } from '@tanstack/react-query';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';

export function BrowseMailchimpIntegration({ userProfile }) {
  const queryClient = useQueryClient();
  const [apiKey, setApiKey] = useState("");
  const [openDialog, setOpenDialog] = useState({
    auth: false,
    detail: false
  });
  const [isApiKeyValid, setIsApiKeyValid] = useState(true);
  const [savedApiKey, setSavedApiKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  // Add state for audiences
  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState("");
  const [isLoadingAudiences, setIsLoadingAudiences] = useState(false);
  
  // Add state for fields
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [genderField, setGenderField] = useState(null);
  const [ageGroupField, setAgeGroupField] = useState(null);

  const activateIntegrations = useAuthenticateIntegrations();
  const createMailchimpFields = useCreateMailchimpFields();
  const getMailchimpAudiences = useGetMailchimpAudiences();
  
  // Get Mailchimp contact fields
  const { data: fieldsData } = useGetMailchimpContactFields(
    selectedAudience,
    userProfile.organisation_id
  );

  const mapFieldsMutation = useMapFieldsInMailchimp();
  const syncGuestsMutation = useSyncGuestDataWithMailchimp();

  const [showSuccess, setShowSuccess] = useState(false);

  const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile.organisation_id);
  
  // Add this function to check integration status
  const isMailchimpActive = () => {
    if (!activeIntegrationsData?.data?.integrations) return false;
    return activeIntegrationsData.data.integrations.some(
      integration => integration.name === "mailchimp"
    );
  };

  console.log('activeIntegrationsData:', activeIntegrationsData); 

  const handleOpenChange = (dialog_type, isOpen) => {
    setOpenDialog({
      ...openDialog,
      [dialog_type]: isOpen
    });
    if (!isOpen) {
      handleCancelAuthDialog();
    }
  };

  const handleInputFieldChange = (event) => {
    setApiKey(event.target.value);
    setIsApiKeyValid(true); // Reset validation on input change
  };

  const handleCancelAuthDialog = () => {
    setApiKey("");
    setIsApiKeyValid(true);
  };

  const handleSave = async () => {
    console.log('Save button clicked');
    if (!apiKey) {
      setIsApiKeyValid(false);
      return;
    }
  
    setIsLoading(true);
    try {
      await activateIntegrations.mutateAsync({
        timestamp: Date.now(),
        user_id: userProfile.user_id,
        organisation_id: userProfile.organisation_id,
        type: "mailchimp",
        state: "active",
        sub_payload: { api_key: apiKey }
      });

      setSavedApiKey(apiKey);

      await createMailchimpFields.mutateAsync({
        organisation_id: userProfile.organisation_id
      });

      queryClient.invalidateQueries(['integrations']);
      handleOpenChange("auth", false);
      handleOpenChange("detail", true);
        
    } catch (error) {
      console.error('Validation error:', error);
      setIsApiKeyValid(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fieldsData?.data.fields) {
      const textFields = fieldsData?.data.fields.filter(field => field.type === "text");
      console.log('textFields:', textFields);
      const foundGenderField = textFields.find(field => field.tag === "GENDER");
      const foundAgeGroupField = textFields.find(field => field.tag === "AGE GROUP");
      
      setGenderField(foundGenderField || null);
      setAgeGroupField(foundAgeGroupField || null);
      
      // Only set selections if fields are found
      if (foundGenderField) setSelectedGender(foundGenderField.tag);
      if (foundAgeGroupField) setSelectedAgeGroup(foundAgeGroupField.tag);
    }
  }, [openDialog.detail, fieldsData, selectedAudience]);

  // Handle audiences fetch
  useEffect(() => {
    const fetchAudiences = async () => {
      if (openDialog.detail && savedApiKey) {
        setIsLoadingAudiences(true);
        try {
          const response = await getMailchimpAudiences.mutateAsync({
            user_id: userProfile.user_id,
            organisation_id: userProfile.organisation_id,
            type: "mailchimp",
            state: "active",
            sub_payload: { api_key: savedApiKey }
          });
  
          if (response?.audiences) {
            setAudiences(response.audiences);
            if (response.audiences.length > 0) {
              setSelectedAudience(response.audiences[0].id);
            }
          }
        } catch (error) {
          console.error('Failed to fetch audiences:', error);
          setAudiences([]);
        } finally {
          setIsLoadingAudiences(false);
        }
      }
    };
  
    fetchAudiences();
  }, [openDialog.detail, savedApiKey, userProfile.user_id, userProfile.organisation_id]);
  
  const handleActivate = async () => {
    try {
      // First map the fields
      await mapFieldsMutation.mutateAsync({
        audienceId: selectedAudience,
        organisation_id: userProfile.organisation_id,
        age_field_tag: selectedAgeGroup,
        gender_field_tag: selectedGender
      });

      // Then sync guest data
      await syncGuestsMutation.mutateAsync({
        audienceId: selectedAudience,
        organisation_id: userProfile.organisation_id
      });
  
      // Close the detail dialog
      handleOpenChange("detail", false);
      handleOpenChange("auth", false);
  
      // Invalidate queries to refresh data
      queryClient.invalidateQueries(['integrations']);
      queryClient.invalidateQueries(['mailchimp-fields']);

      setShowSuccess(true);
  
    } catch (error) {
      console.error('Error during field mapping:', error);
    }
  };



  return (
    <>
    <BrowseIntegrateCard.Root name="Mailchimp" subtitle="Email marketing" image_url="mailchimp.png" status={isMailchimpActive() ? "active" : "inactive"} openDialog={openDialog} handleOpenChange={handleOpenChange}>
      <BrowseIntegrateCard.AuthDialog 
        dialog_description="You can find your API key by logging into Mailchimp, navigating to Account Settings > Extras > API Keys, and copying an existing key or creating a new one."
        onCancel={handleCancelAuthDialog}
        leftButton={
          <Button size="2" variant="ghost" color="gray" onClick={() => window.open('https://mailchimp.com/', '_blank')} style={{ height: "24px", marginLeft: "1px" }}>
            <Flex display="flex" align="center" gap="2">
              Open Mailchimp
              <ArrowTopRightOnSquareIcon style={{ width: '16px', height: '16px' }} />
            </Flex>
          </Button>
        }
      >
        <Flex className="slot-container" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="5">
          <Flex className="api-key-field" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="1">
            <Text size="2" weight="medium">
              API key
            </Text>
            <TextField.Root 
              onChange={handleInputFieldChange}
              value={apiKey}
              placeholder='Paste your key here'
              style={{alignSelf: "stretch"}} 
              size="2" 
              variant="classic"
            />
            {!isApiKeyValid && 
              <Text size="1" weight="regular" color="red">
                Please enter a valid API key.
              </Text>
            }
          </Flex>
        </Flex>
      </BrowseIntegrateCard.AuthDialog>

      <BrowseIntegrateCard.DetailDialog onSave={handleSave} onActivate={handleActivate}>
        <BrowseIntegrateCard.DetailDialogSection 
          name="Linked Audience" 
          subtitle="Select the Mailchimp audience you want to associate with your b connect organisation."
        >
          <Flex direction="column" gap="1">
            <Text size="2" weight="regular">
              Mailchimp Audience
            </Text>
            <Select.Root 
              value={selectedAudience}
              onValueChange={setSelectedAudience}
              style={{ width: '648px', alignSelf: 'stretch' }}
            >
              <Select.Trigger style={{ width: '648px' }}/>
              <Select.Content>
                {isLoadingAudiences ? (
                  <Select.Item value="loading">Loading audiences...</Select.Item>
                ) : audiences.length > 0 ? (
                  audiences.map((audience) => (
                    <Select.Item key={audience.id} value={audience.id}>
                      {audience.name}
                    </Select.Item>
                  ))
                ) : (
                  <Select.Item value="no-audience">No audiences found</Select.Item>
                )}
              </Select.Content>
            </Select.Root>
          </Flex>
        </BrowseIntegrateCard.DetailDialogSection>

        <BrowseIntegrateCard.DetailDialogSection 
          name="Data fields" 
          subtitle={
            <>
              <p style={{ marginBottom: '8px' }}>Confirm the data fields that are being mapped from b connect to Mailchimp.</p>
              <p>We've introduced custom Mailchimp fields for "Age group" and "Gender". You can create different custom fields in Mailchimp.</p>
            </>
          }
        >
          <Flex direction="column" gap="1">
            <Flex direction="row" justify="between" style={{ width: '648px' }}>
              {/* Left Column */}
              <Flex direction="column" gap="1" style={{ width: '300px' }}>
                <Text size="2" weight="regular">
                  b connect
                </Text>
                <TextField.Root 
                  variant="surface" 
                  value="Email address" 
                  size="2"
                  disabled
                  style={{ 
                    width: '100%',
                    backgroundColor: 'var(--gray-3)',
                    cursor: 'not-allowed'
                  }} 
                />
              </Flex>

              <Flex align="center" style={{ paddingTop: '24px' }}> 
                <IconButton size="2" variant="ghost" color="gray">
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>

              {/* Right Column */}
              <Flex direction="column" gap="1" style={{ width: '300px' }}>
                <Text size="2" weight="regular">
                  Mailchimp
                </Text>
                <Select.Root 
                  value="email"
                  disabled
                  style={{ width: '100%' }}
                >
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Item value="email">Email address</Select.Item>
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>

            <Flex align="center" style={{ margin: '16px 0' }}>
              <Separator style={{ flex: 1 }} />
              <Text size="2" weight="regular" style={{ margin: '0 16px' }}>
                Custom fields
              </Text>
              <Separator style={{ flex: 1 }} />
            </Flex>

            <Flex direction="row" justify="between" style={{ width: '648px' }}>
              {/* Left Column */}
              <Flex direction="column" gap="1" style={{ width: '300px' }}>
                <TextField.Root 
                  variant="surface" 
                  value="Gender" 
                  size="2"
                  disabled
                  style={{ 
                    width: '100%',
                    backgroundColor: 'var(--gray-3)',
                    cursor: 'not-allowed'
                  }} 
                />
              </Flex>

              <Flex align="center"> 
                <IconButton size="2" variant="ghost" color="gray">
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>

              {/* Right Column */}
              <Flex direction="column" gap="1" style={{ width: '300px' }}>
              <Select.Root
                value={selectedGender}
                disabled={!genderField}
                style={{ width: '100%' }}
                onValueChange={setSelectedGender}
              >
                <Select.Trigger />
                <Select.Content>
                  {fieldsData?.data?.fields
                    ?.filter(field => field.type === "text")
                    .map(field => (
                      <Select.Item key={field.tag} value={field.tag}>
                        {field.name}
                      </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
              </Flex>
            </Flex>    

            <Flex direction="row" justify="between" style={{ width: '648px', paddingTop:'16px' }}>
              {/* Left Column */}
              <Flex direction="column" gap="1" style={{ width: '300px' }}>
                <TextField.Root 
                  variant="surface" 
                  value="Age group" 
                  size="2"
                  disabled
                  style={{ 
                    width: '100%',
                    backgroundColor: 'var(--gray-3)',
                    cursor: 'not-allowed'
                  }} 
                />
              </Flex>

              <Flex align="center"> 
                <IconButton size="2" variant="ghost" color="gray">
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>

              {/* Right Column */}
              <Flex direction="column" gap="1" style={{ width: '300px' }}>
                <Select.Root
                  value={selectedAgeGroup} 
                  disabled={!ageGroupField}
                  style={{ width: '100%' }}
                  onValueChange={setSelectedAgeGroup}
                >
                  <Select.Trigger />
                  <Select.Content>
                    {fieldsData?.data?.fields
                      ?.filter(field => field.type === "text")
                      .map(field => (
                        <Select.Item key={field.tag} value={field.tag}>
                          {field.name}
                        </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>   
          </Flex>
        </BrowseIntegrateCard.DetailDialogSection>
      </BrowseIntegrateCard.DetailDialog>
    </BrowseIntegrateCard.Root>
          <ActivatedIntegrationCard 
          isOpen={showSuccess} 
          onClose={() => setShowSuccess(false)} 
          description={"Your customers will now sync with your selected Mailchimp audience."}
        />
      </>
  );
}