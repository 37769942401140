import * as React from "react";
import { Flex, Text, Button, Tabs, Dialog, Box } from "@radix-ui/themes";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { tabs, snackTextObject } from './SnackText';

// Utility function to bold text between asterisks
function BoldedText({ input }) {
  const regex = /\*(.*?)\*/g; // Regular expression to match text between asterisks

  // Split the string into parts, including the matched bold text
  const parts = input.split(regex);
  let unboldedFirstPart = null;
  let boldedPart = null;
  let unboldedSecondPart = null;

  parts.map((part, index) => {
    // Alternate between bold and normal text
    if (index === 0) {
      // This is the first half of the unbolded text
      unboldedFirstPart = part;
    } else if (index === 1) {
      boldedPart = part;
    } else if (index === 2) {
      // This is the second half of the unbolded text
      unboldedSecondPart = part;
    }
  });

  return (
    <Text size="2" weight="regular">
      {unboldedFirstPart} <Text as="span" size="2" weight="bold">{boldedPart}</Text> {unboldedSecondPart}
    </Text>
  );
}

// Utility functions for random praise
function randomPraiseOne() {
  const praiseArray = [
    "fantastic",
    "amazing",
    "excellent",
    "impressive",
    "wonderful",
    "incredible",
    "outstanding",
    "remarkable",
    "stellar",
    "great",
    "brilliant",
    "strong",
    "noteworthy",
    "superb",
    "exceptional",
    "dynamic",
    "exciting",
    "thriving"
  ];
  const randomIndex = Math.floor(Math.random() * praiseArray.length);
  return praiseArray[randomIndex];
}

// Another utility function for random praise
function randomPraiseTwo() { 
  const praise = randomPraiseOne();
  let modifiedPraise
  if (["a", "e", "i", "o", "u"].includes(praise[0])) {
    modifiedPraise = `an ${praise}`;
  } else {
    modifiedPraise = `a ${praise}`;
  }
  return modifiedPraise;
}

export function SnackInsights({ show, onClose, greeting, firstName, data }) {

  // We need to do two things here:
  // 1. Open the dialog when we reach a specific date-time
  // 2. Render the text depending on what time of the day and day of the week it
  const [statistics, setStatistics] = React.useState({});

  // Here, we invoke the praise functions when the dialog is opened!
  const [praiseOne, setPraiseOne] = React.useState('');
  const [praiseTwo, setPraiseTwo] = React.useState('');

  React.useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    const dayOfWeek = now.getDay();

    Object.keys(tabs).forEach(keyOne => {
      const [lower, upper] = keyOne.split("-").map(Number);
      if (dayOfWeek >= lower && dayOfWeek <= upper) {

        Object.keys(tabs[keyOne]).forEach(keyTwo => {
          const [startHour, endHour] = keyTwo.split("-").map(Number);
          if (hour >= startHour && hour <= endHour) {
            const [week, day] = tabs[keyOne][keyTwo];
            setStatistics({
              ...snackTextObject(data, week, day, praiseOne, praiseTwo),
              tabs: [week, day]
            });
          }
        });
      }
    })

  }, [data, praiseOne, praiseTwo]);

  const [isDialogOpen, setIsDialogOpen] = React.useState(show);

  React.useEffect(() => {
    setPraiseOne(randomPraiseOne());
    setPraiseTwo(randomPraiseTwo());
    setIsDialogOpen(show);
  }, [show]);

  const handleOpenChange = (open) => {
    setIsDialogOpen(open);
    if (!open) {
      onClose();
    }
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <Button size="2" variant="outline" color="gray" style={{cursor: "pointer"}} ><SparklesIcon width="16px" height="16px"/>Insights</Button>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="600px" maxHeight="690px" align="center  ">
        <Dialog.Title>{`Good ${greeting} ${firstName}`}</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          <Text style={{color: "var(--gray-11)"}}>
            Here's a summary of all your locations...
          </Text>
        </Dialog.Description>

        <Flex direction="column" gap="3">
        <Tabs.Root defaultValue="0">
          <Tabs.List>
            {statistics.tabs?.map((tab, index) => (
              <Tabs.Trigger key={index} value={index.toString()} style={{cursor: "pointer"}}>{tab}</Tabs.Trigger>
            ))}
          </Tabs.List>

          <Box pt="4">
            <Tabs.Content value="0">
              <Flex className="slot-container" style={{outline: "none"}} tabIndex={-1} display="flex" direction="column" gap="4" >
                {statistics.text_one?.map((text, index) => (
                  <Flex className="card" key={index} style={{alignSelf: "stretch", border: "1px solid var(--gray-a6)", background: "linear-gradient(100deg, var(--gray-2) 35.19%, var(--gray-3) 64.81%)", borderRadius: "var(--space-1)"}} display="flex" align="start" p="4" gap="2" direction="column" justify="center">
                    <BoldedText input={text.main}/>
                    <Text style={{color: "var(--gray-11)"}} size="1" weight="regular">
                      {text.sub}
                    </Text>
                  </Flex >
                ))}
              </Flex>
            </Tabs.Content>

            <Tabs.Content value="1">
              <Flex className="slot-container" style={{outline: "none"}} tabIndex={-1} display="flex" direction="column" gap="4">
                {statistics.text_two?.map((text, index) => (
                  <Flex className="card" key={index} style={{alignSelf: "stretch", border: "1px solid var(--gray-a6)", background: "linear-gradient(100deg, var(--gray-2) 35.19%, var(--gray-3) 64.81%)", borderRadius: "var(--space-1)"}} display="flex" align="start" p="4" gap="2" direction="column" justify="center">
                    <BoldedText input={text.main}/>
                    <Text style={{color: "var(--gray-11)"}} size="1" weight="regular">
                      {text.sub}
                    </Text>
                  </Flex >
                ))}
              </Flex>
            </Tabs.Content>
          </Box>
        </Tabs.Root>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" style={{cursor: "pointer"}} onClick={() => setIsDialogOpen(false)}>
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
