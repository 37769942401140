import { Dialog, Text, Button, Flex } from '@radix-ui/themes';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export function ActivatedIntegrationCard({ isOpen, onClose, description }) {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Content style={{
        width: '296px',
        height: '222px',
        maxWidth: '1000px',
        padding: '24px',
        gap: '24px',
        borderRadius: '8px',
        overflow: 'hidden'
      }}>
        <Flex direction="column" align="center" gap="4">
          <CheckCircleIcon 
            style={{
              width: '24px',
              height: '24px',
            }}
          />
          <Text 
            style={{
                fontSize: '24px',
                fontWeight: '700'
            }}
          >
            Activated
          </Text>
          <Text
            style={{
              fontSize: '12px',
              textAlign: 'center',
              color: 'var(--gray-11)'
            }}
          >
            {description}
          </Text>
          <Button variant="soft" size="2" onClick={onClose} style={{width:"100%"}}>
            Done
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}