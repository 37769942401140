import * as React from 'react';
import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { Icon } from 'components/tokens/Icon';
import { Flex, IconButton, Text, TextField, Button, ScrollArea } from '@radix-ui/themes';
import { CopyIcon, ReloadIcon } from '@radix-ui/react-icons';
import { ArrowRightIcon, PaperAirplaneIcon, RectangleStackIcon, PlusIcon, 
    HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/16/solid';
import { useGetbChatResponse, useProfile } from 'hooks';

const BChatHistory = lazy(() => import('./bChatHistory'));
const BChatPromptLibrary = lazy(() => import('./bChatPromptLibrary'));

// Message component to display chat messages
const ChatMessage = ({ message, isUser }) => (
    <Flex gap="2" style={{alignSelf: isUser ? 'flex-end' : 'flex-start'}}>
        <Flex width="24px" height="24px" p="2" style={{borderRadius: 'var(--radius-2)', maxWidth: '80%',
        display: isUser ? 'none' : 'flex', background: 'var(--blue-9)', justifyContent: 'center', alignItems: 'center'}}>
            <Icon name="LogoBWifi" color="white" width="16px" height="12px"/>
        </Flex>
        <Flex direction="column" gap="2" style={{padding: '12px',
            borderRadius: 'var(--radius-4)', background: isUser ? 'var(--blue-3)' : 'var(--color-panel-solid)',
            border: '1px solid var(--gray-6)', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
        }}>
            <Text size="3" >{message}</Text>
            {/* Feedback Options */}
            <Flex gap="2" style={{alignItems: 'flex-start', display: isUser ? 'none' : 'flex'}}>
                <IconButton size="1" variant="ghost" color="gray" style={{cursor: 'pointer'}}>
                    <CopyIcon/>
                </IconButton>
                <IconButton size="1" variant="ghost" color="gray" style={{cursor: 'pointer'}}>
                    <HandThumbUpIcon width="16" height="16"/>
                </IconButton>
                <IconButton size="1" variant="ghost" color="gray" style={{cursor: 'pointer'}}>
                    <HandThumbDownIcon width="16" height="16"/>
                </IconButton>
                <IconButton size="1" variant="ghost" color="gray" style={{cursor: 'pointer'}}>
                    <ReloadIcon/>
                </IconButton>
            </Flex>
        </Flex>
    </Flex>
);

export default function BChat() {

    const { data: profile} = useProfile();
    const [userId, setUserId] = useState(null);
    useEffect(() => {
        if (profile) {
          setUserId(profile.id);
        }
    }, [profile]);
    console.log(userId);

    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const [isPromptLibraryOpen, setIsPromptLibraryOpen] = useState(true);
    const [isPromptLibrarySidebarOpen, setIsPromptLibrarySidebarOpen] = useState(false);
    
    const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
    const toggleChatHistory = () => setIsChatHistoryOpen(!isChatHistoryOpen);
    
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);
    const { getBotResponse, loading, error } = useGetbChatResponse();
    const [conversationId, setConversationId] = useState(null);

    // Send message to chat
    const sendMessage = async () => {
        if (!inputMessage.trim()) return;
    
        // Add user message to chat
        const userMessage = { text: inputMessage, isUser: true };
        setMessages(prev => [...prev, userMessage]);
        setIsLoading(true);
    
        // Get response from chatbot
        const response = await getBotResponse(inputMessage, userId, conversationId);
        const botMessage = { text: response?.message, isUser: false };
        setMessages(prev => [...prev, botMessage]);
        setConversationId(response?.conversation_id);
    
        setInputMessage('');
        setIsLoading(false);
        inputRef.current.focus();
    };

    // Listen for Enter key press to send message
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter' && !e.shiftKey && inputMessage.trim()) {
                sendMessage();
                setIsPromptLibraryOpen(false);
                setIsChatHistoryOpen(true);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        inputRef.current.focus();
        return () => document.removeEventListener('keydown', handleKeyDown);
    });

    // Scroll to the bottom of the chat when new messages are added
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    // Always have the Focus on the input field so that user can start typing right away
    useEffect(() => {
        inputRef.current.focus();
    }, []);
    

    return (
        <Flex style={{alignItems: 'flex-start', height: '100%'}}>
            <Flex gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch', padding: '28px 12px',
                display: isChatHistoryOpen ? 'none' : 'flex'}}>
                <IconButton color="gray" size="2" variant="ghost" style={{cursor: 'pointer'}}
                    onClick={toggleChatHistory}>
                    <ArrowRightIcon width="16" height="16" />
                </IconButton>
            </Flex>
            <Suspense>
                <BChatHistory 
                    isChatHistoryOpen={isChatHistoryOpen} 
                    setIsChatHistoryOpen={setIsChatHistoryOpen}
                    isPromptLibrarySidebarOpen={isPromptLibrarySidebarOpen}
                    setIsPromptLibrarySidebarOpen={setIsPromptLibrarySidebarOpen}
                    userId={userId}
                />
            </Suspense>
            {/* Prompt Libary */}
            { isPromptLibrarySidebarOpen &&
            <Suspense>
                <BChatPromptLibrary 
                isPromptLibrarySidebarOpen={isPromptLibrarySidebarOpen}
                setIsPromptLibrarySidebarOpen={setIsPromptLibrarySidebarOpen}
                />
            </Suspense>
            }
            {/* Main Content */}
            <Flex gap="8" p="8" direction="column" style={{justifyContent: 'center', alignItems: 'center', 
                alignSelf: 'stretch', flex: '1 0 0'}}>
                {/* Header */}
                <Flex gap="2" justify="between" style={{alignSelf: 'stretch',display: isPromptLibraryOpen? 'none':'flex'}}>
                    <Text size="5" weight="bold">Key Performance Metrics</Text>
                    <Button size="2" variant="soft" style={{padding: '0px 12px', justifyContent: 'center', 
                        alignItems: 'center', height: '32px', display: 'flex', cursor: 'pointer', gap: '8px'}}>
                        <PlusIcon width="16" height="16" />
                        <Text size="2" weight="medium" onClick={() => window.location.reload()}>
                            New Chat
                        </Text>
                    </Button>
                </Flex>
                {/* Chat Area */}
                <Flex direction="column" gap="8"
                    style={{justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'stretch', flex: '1 0 0', overflow:"scroll"}}>
                    {/* ChatF&B */}
                    <Text size="8" weight="bold" style={{display: isPromptLibraryOpen ? 'block' : 'none'}}>
                        ChatF&B
                    </Text>
                    <ScrollArea size="3" scrollbars="vertical"
                        style={{width: '100%', maxWidth: '800px', padding: '20px', height: '100%',
                        borderRadius: 'var(--radius-4)',
                        overflow: 'auto',
                        display: isPromptLibraryOpen ? 'none' : 'block'
                        }}>
                        <Flex direction="column" gap="4">
                            {messages.map((msg, index) => (
                            <ChatMessage key={index} message={msg.text} isUser={msg.isUser} />
                            ))}
                                <Flex ref={messagesEndRef} /> {/* Scroll to bottom */}
                        </Flex>
                    </ScrollArea>
                    {/* ChatInput */}
                    <Flex width='600px' direction="row" gap="4"
                        style={{alignItems: 'center', maxWidth: '600px'}}>
                        <IconButton color="gray" size="3" variant="soft" style={{cursor: 'pointer',
                            display: isPromptLibraryOpen ? 'none' : 'flex'}}>
                            <RectangleStackIcon color='gray' width="18" height="18" />
                        </IconButton>
                        <TextField.Root size="3" placeholder="Message ChatF&B" style={{width: '100%'}}
                        value={inputMessage} 
                        onChange={(e) => setInputMessage(e.target.value)}
                        disabled={isLoading}
                        ref={inputRef}
                        autoFocus={true}
                        />
                        <IconButton color="gray" size="3" variant="soft" 
                            style={{cursor: isLoading ? 'not-allowed' : 'pointer'}}
                            onClick={() => {
                                sendMessage();
                                setIsPromptLibraryOpen(false);
                                setIsChatHistoryOpen(true);
                            }}
                            disabled={isLoading}>
                            <PaperAirplaneIcon width="18" height="18" />
                        </IconButton>
                    </Flex>
                </Flex>
                {/* Suggestive Prompts Section */}
                <Flex gap="5" direction="column" width="600px" style={{maxWidth: '600px', alignItems: 'flex-start',
                display: isPromptLibraryOpen ? 'flex' : 'none'
                }}>
                    <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                        {/* Card Row 1 */}
                        <Flex gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                            <Flex p="3" style={{cursor: 'pointer', borderRadius: 'var(--radius-4)',
                                background: "linear-gradient(111deg, var(--orange-4) 22.52%, var(--orange-3) 77.48%)"}} 
                                onClick={() => {setInputMessage('What are the wifi credentials to give to customers?');
                                    sendMessage();
                                    setIsPromptLibraryOpen(false);
                            }}>
                                <Text size="2" weight="regular">What are the wifi credentials to give to customers?</Text>
                            </Flex>
                            <Flex p="3" style={{cursor: 'pointer', borderRadius: 'var(--radius-4)',
                                background: "linear-gradient(111deg, var(--blue-4) 22.52%, var(--blue-3) 77.48%)"}}
                                onClick={() => {setInputMessage('What are the latest sales figures for our restaurant?');
                            }}>
                                <Text size="2" weight="regular">What are the latest sales figures for our restaurant?</Text>
                            </Flex>
                        </Flex>
                        {/* Card Row 2 */}
                        <Flex gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                            <Flex p="3" style={{cursor: 'pointer', borderRadius: 'var(--radius-4)',
                                background: "linear-gradient(111deg, var(--purple-4) 22.52%, var(--purple-3) 77.48%)"}}
                                onClick={() => {setInputMessage('What are the peak hours for customer visits?');
                            }}>
                                <Text size="2" weight="regular">What are the peak hours for customer visits?</Text>
                            </Flex>
                            <Flex p="3" style={{cursor: 'pointer', borderRadius: 'var(--radius-4)',
                                background: "linear-gradient(111deg, var(--violet-4) 22.52%, var(--violet-3) 77.48%)"}}
                                onClick={() => {setInputMessage('What are the most popular dishes on the menu?');
                            }}>
                                <Text size="2" weight="regular">What are the most popular dishes on the menu?</Text>
                            </Flex>
                        </Flex>
                        {/* Card Row 3 */}
                        <Flex gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                            <Flex p="3" style={{cursor: 'pointer', borderRadius: 'var(--radius-4)',
                                background: "linear-gradient(111deg, var(--teal-4) 22.52%, var(--teal-3) 77.48%)"}}
                                onClick={() => {setInputMessage('Can you summarize our restaurant\'s online reviews?');
                            }}>
                                <Text size="2" weight="regular">Can you summarize our restaurant's online reviews?</Text>
                            </Flex>
                            <Flex p="3" style={{cursor: 'pointer', borderRadius: 'var(--radius-4)',
                                background: "linear-gradient(111deg, var(--red-4) 22.52%, var(--red-3) 77.48%)"}}
                                onClick={() => {setInputMessage('What promotions can we run based on footfall data?');
                            }}>
                                <Text size="2" weight="regular">What promotions can we run based on footfall data?</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Button size="3" variant="ghost" color='gray'
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch',
                        gap: '8px', padding: '0px 12px', cursor: 'pointer'
                        }}>
                        <RectangleStackIcon color='gray' width="16" height="16" />
                        <Text>Prompt Library</Text>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
}
