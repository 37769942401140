import { useState } from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { MenuItem } from 'components';
import { Outlet, useNavigate } from 'react-router-dom';

export function IntegrationsLayout() {
  const navigate = useNavigate();

  const [integrationsMenuSelection, setIntegrationsMenuSelection] = useState("active");

  const handleIntegrationsMenuSelection = (flag) => {
    setIntegrationsMenuSelection(flag);
    navigate(flag);
  }

  return (
    <Flex className="guest-wifi-container" display="flex" direction="column" align="start" flexGrow="1">
      <Flex className="guest-wifi-layout" style={{alignSelf: "stretch"}} display="flex" p="8" direction="column" align="start" gap="8">
        <Flex className="header-container" style={{alignSelf: "stretch"}} display="flex" justify="center" align="end" gap="2" >
          <Flex className="date-greeting-container" display="flex" justify="between" align="end" flexGrow="1">
            <Text size="8" weight="bold">Integrations</Text>
            <Flex display="flex" height="var(--space-6)" p="3" justify="center" align="center" gap="2">
              <Button variant='ghost' color='gray' onClick={() => {
                const supportEmail = 'support@b-connect.co.uk';
                const subject = 'Support Request';
                const body = 'Hello, I need help with...';
                const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                window.location.href = mailtoLink; }} style={{cursor:"pointer"}}>
                Contact support
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex className="main-content" style={{alignSelf: "stretch"}} display="flex" align="start" gap="8"> 
          <Flex className="main-content-sidebar" display="flex" width="144px" direction="column" align="start" gap="1">
            <MenuItem flag="active" state={integrationsMenuSelection} onClick={handleIntegrationsMenuSelection} text="Active" />
            <MenuItem flag="browse-all" state={integrationsMenuSelection} onClick={handleIntegrationsMenuSelection} text="Browse all" />
          </Flex>
          <Flex style={{alignSelf: 'stretch', flex: '1 0 0', alignItems: 'flex-start', height: '100%'}}>
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}