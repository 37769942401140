import instance from './BasebChatApi';

export function attemptGetbChatResponse(message, user_id, conversation_id){
  const payload = {
    message : message,
    user_id : user_id,
    conversation_id : conversation_id,
  };
  console.log('Sending payload:', payload); // Debug log
  return instance.request({
    url: 'user/messages/',
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: payload,
  });
}

export function attemptGetbChatHistory(user_id){
  return instance.request({
    url: `users/${user_id}/conversations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
