import { useState, useEffect } from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { MenuItem } from 'components';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export function WifiLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to get menu state from path
  const getMenuStateFromPath = (path) => {
    if (path.includes('/wifi/analytics')) return 'analytics';
    if (path.includes('/wifi/locations')) return 'locations';
    if (path.includes('/wifi/guest-portal')) return 'guest-portal';
    if (path.includes('/wifi/networks')) return 'networks';
    return 'analytics'; // default
  }

  // State to manage menu selection
  const [wifiMenuSelection, setWifiMenuSelection] = useState(
    getMenuStateFromPath(location.pathname)
  );

  // Function to handle menu selection
  const handleWifiMenuSelection = (flag) => {
    setWifiMenuSelection(flag);
    navigate(flag);
  }

  // Update menu selection on path change
  useEffect(() => {
    setWifiMenuSelection(getMenuStateFromPath(location.pathname));
  }, [location.pathname]);

  return (
    <Flex className="guest-wifi-container" direction="column" height="100%" 
      style={{alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch'}}>
      <Flex className="guest-wifi-layout" p="8" direction="column" gap="8" height="100%" 
        style={{alignSelf: "stretch", alignItems: 'flex-start', flex: '1 0 0'}}>
        <Flex className="header-container" style={{alignSelf: "stretch"}} justify="center" align="end" gap="2" >
          <Flex className="date-greeting-container" justify="between" style={{flex: '1 0 0',
            alignItems: 'center', alignSelf: 'stretch'}}>
            <Text size="8" weight="bold">Wi-Fi</Text>
            <Flex height="var(--space-6)" p="3" justify="center" align="center" gap="2">
              <Button variant='ghost' color='gray' onClick={() => {
                const supportEmail = 'support@b-connect.co.uk';
                const subject = 'Support Request';
                const body = 'Hello, I need help with...';
                const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                window.location.href = mailtoLink; }} style={{cursor:"pointer"}}>
                Contact support
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex className="main-content" height="100%" gap="8" 
          style={{alignSelf: "stretch", flex: '1 0 0', alignItems: 'flex-start'}}> 
          <Flex className="main-content-sidebar" width="144px" direction="column" gap="1" height="100%" 
            style={{alignItems: 'flex-start'}}>
            <MenuItem flag="analytics" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Analytics" />
            <MenuItem flag="locations" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Locations" />
            <MenuItem flag="guest-portal" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Portal" />
            <MenuItem flag="networks" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Networks" />
          </Flex>
          <Flex style={{alignSelf: 'stretch', flex: '1 0 0', alignItems: 'flex-start', height: '100%'}}>
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}