// hooks/useLiveConnectionData.js
import { useState, useEffect } from 'react';
import { useGetLiveConnectionStats } from '../app/useGetLiveConnectionStats';

export const useLiveConnectionStats = (locationSelection) => {
  const [accessPointMacList, setAccessPointMacList] = useState([]);
  const [liveConnectionPayload, setLiveConnectionPayload] = useState(null);
  const [liveConnectionStat, setLiveConnectionStat] = useState([]);

  console.log("locationsData", locationSelection);
  
  useEffect(() => {
    if (locationSelection.length > 0) {
      // Prepare payload for fetching live connection stats
      setLiveConnectionPayload({
        timestamp: Date.now(),
        request_type: "live-connections-metrics",
        payload: {
          location_ids: locationSelection,
        },
      });
    }
  }, [locationSelection]);

  const { data: liveConnectionStats } = useGetLiveConnectionStats(liveConnectionPayload);

  useEffect(() => {
    if (liveConnectionStats) {
      const liveData = liveConnectionStats.data.data;
      setLiveConnectionStat([
        {
          title: "Guests connected",
          value: liveData.live_connections,
          subValue: null,
          badge: false,
        },
        {
          title: "Returning-guests connected",
          value: liveData.returning_connections,
          subValue: liveData.returning_connection_percent,
          badge: false,
          separator: true,
        },
        {
          title: "New-guests connected",
          value: liveData.new_connections,
          subValue: liveData.new_connection_percent,
          badge: false,
        },
        {
          title: "Wi-Fi access points",
          value: liveData.offline_access_points_count,
          subValue: liveData.online_access_points_count,
          badge: true,
        },
      ]);
    }
  }, [liveConnectionStats]);

  // Update live stats every 30 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLiveConnectionPayload(prevPayload => ({
        ...prevPayload,
        timestamp: Date.now(),
      }));
    }, 30000);

    return () => clearInterval(intervalId);
  }, [accessPointMacList]);

  return {
    liveConnectionStat,
  };
};