import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { differenceInDays, startOfDay, isEqual } from 'date-fns';
import { Text, Flex, Separator, Badge, Tooltip, Button } from "@radix-ui/themes";
import { GearIcon } from '@radix-ui/react-icons';
import { Header, LiveConnectionButton, AvatarDropdownMenu, SnackInsights, DatePickerWithRange, LiveStatCard, MetricCard, ChartCard, LocationMultiSelect } from "components";
import { useDateRange, useLiveConnectionStats, useMetricsData, useWeekMetrics } from "hooks";
import { useProfileContext, useLocationContext, useDateRangeContext } from "context";
import { GuestConnectionsByTimeOfDayChart } from "components/charts/GuestConnectionsByTimeOfDayChart";
import { GuestConnectionsByDayChart } from "components/charts/GuestConnectionsByDayChart";
import { GuestConnectionsByDayOfWeekChart } from "components/charts/GuestConnectionsByDayOfWeekChart";
import { GuestConnectionsByMonthChart } from "components/charts/GuestConnectionsByMonthChart";
import { GuestConnectionsByLocationsChart } from "components/charts/GuestConnectionsByLocationsChart";
import { UniqueGuestsByAgeChart } from "components/charts/UniqueGuestsByAgeChart";
import { UniqueGuestsByGenderChart } from "components/charts/UniqueGuestsByGenderChart";
import { UsersIcon, UserIcon, ArrowUturnLeftIcon, PlusIcon, ClockIcon } from "@heroicons/react/16/solid"
import { useTheme } from "../../context/ThemeContext";
import  NewReturningLegend  from "components/charts/NewReturningLegend";
import { getDateTimeConnectionsData } from "../../helpers/metricsUtils";
import { calculateHighestAndLowestGenderGroups } from "../../helpers/metricsUtils";
import { chartConfig } from '../../configs/ChartConfig';

export default function Dashboard() {

  const { userProfile } = useProfileContext();
  const { initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess, allLocations } = useLocationContext();
  const { selectedDateRange, setSelectedDateRange, dateRangeDifference } = useDateRangeContext();

  // Custom hooks
  const { liveConnectionStat } = useLiveConnectionStats(locationSelection);
  const { 
    metricsData,
    guestConnectionsChartData, 
    guestConnectionsByLocationsChartData 
  } = useMetricsData(selectedDateRange, locationSelection, userProfile.user_id, isLocationsSuccess);

  const { setAppearance } = useTheme();
  const [showLiveConnectionCards, setShowLiveConnectionCards] = useState(true);

  const toggleLiveConnectionCards = () => setShowLiveConnectionCards(locationSelection.length > 0 ? !showLiveConnectionCards : false);

  // Use useEffect to set toggleLiveConnectionCards to false when locationSelection is empty

  useEffect(() => {
    if (locationSelection.length === 0) {
      setShowLiveConnectionCards(false);
    } else {
      setShowLiveConnectionCards(true);
    }
  }, [locationSelection]);

  var metricTileDataOne = [
    {
      name: "Guest connections",
      icon: <UsersIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.guest_connections ?? 'Loading...',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_gc_change >= 0 ? '+' : ''}${metricsData?.last_period_gc_change ?? '...'}% since last period`,
      tooltip: 'Total guest connections in the selected time range.'
    },
    {
      name: "Unique guests",
      icon: <UserIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.unique_guests ?? 'Loading...',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_ug_change >= 0 ? '+' : ''}${metricsData?.last_period_ug_change ?? '...'}% since last period`,
      tooltip: 'Distinct guests connected in the time range.'
    },
    {
      name: "Unique returning-guests",
      icon: <ArrowUturnLeftIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.unique_returning_guests ?? 'Loading...',
      subMetric: metricsData?.unique_guests && metricsData?.unique_returning_guests
        ? `${((metricsData.unique_returning_guests / metricsData.unique_guests) * 100).toFixed(1)}%`
        : '0.0%',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_urg_change >= 0 ? '+' : ''}${metricsData?.last_period_urg_change ?? '...'}% since last period`,
      tooltip: 'Distinct returning guests that connected during the time range.'
    },
    {
      name: "Unique new-guests", 
      icon: <PlusIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.unique_new_guests ?? 'Loading...',
      subMetric: metricsData?.unique_guests && metricsData?.unique_new_guests
        ? `${((metricsData.unique_new_guests / metricsData.unique_guests) * 100).toFixed(1)}%`
        : '0.0%',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_ung_change >= 0 ? '+' : ''}${metricsData?.last_period_ung_change ?? '...'}% since last period`,
      tooltip: 'Distinct new guests that connected during the time range.'
    },
  ];

  var metricTileDataTwo = [
    {
      name: "Avg. time of guest connection", 
      icon: <ClockIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: `${metricsData?.average_connection_duration ?? 'Loading...'} mins`,
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_acd_change >= 0 ? '+' : ''}${metricsData?.last_period_acd_change ?? '...'}% since last period`,
      tooltip: 'Average connection time per guest.'
    },
    {
      name: "Avg. hourly guest connections",
      metric: metricsData?.average_hourly_connections ?? 'Loading...',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_ahc_change >= 0 ? '+' : ''}${metricsData?.last_period_ahc_change ?? '...'}% since last period`,
      tooltip: 'Average connections per hour, based on 24 hours in a day.'
    },
    {
      name: "Avg. daily guest connections",
      metric: (dateRangeDifference > 1) ? metricsData?.average_daily_connections ?? 'Loading...' : '',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : (dateRangeDifference > 1) ? `${metricsData?.last_period_adc_change >= 0 ? '+' : ''}${metricsData?.last_period_adc_change ?? '...'}% since last period` : 'Select a multi-day range to see Avg. Daily Connections',
      tooltip: 'Average guest connections per day.'
    },
    {
      name: "Avg. weekly guest connections",
      metric: (dateRangeDifference > 6) ? metricsData?.average_weekly_connections ?? 'Loading...' : '',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : (dateRangeDifference > 6) ? `${metricsData?.last_period_awc_change >= 0 ? '+' : ''}${metricsData?.last_period_awc_change ?? '...'}% since last period` : 'Select a multi-week range to see Avg. Weekly Connections',
      tooltip: 'Average guest connections per week.'
    }
  ];


  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  // Normalize the dates to the start of the day
  const normalizedFrom = startOfDay(selectedDateRange.from);
  const normalizedTo = startOfDay(selectedDateRange.to);

  // Calculate the number of days in the selected date range (inclusive)
  const selectedDateRangeInDays = Math.ceil((normalizedTo.getTime() - normalizedFrom.getTime()) / oneDayInMilliseconds) + 1;


  const [showSnackInsights, setShowSnackInsights] = useState(false);
  const [greeting, setGreeting] = useState(localStorage.getItem('greeting') || "");
  const [dialogShownRecently, setDialogShownRecently] = useState(false);

  const determineGreeting = () => {
    const currentHour = new Date().getHours();
    let newGreeting = "";
    if (currentHour >= 0 && currentHour < 12) {
      newGreeting = "Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      newGreeting = "Afternoon";
    } else if (currentHour >= 17 && currentHour < 24) {
      newGreeting = "Evening";
    }
    setGreeting(newGreeting);
    localStorage.setItem('greeting', newGreeting);
  };

  useEffect(() => {
    const checkTimeAndShowDialog = () => {
      const now = new Date();
      const currentHour = now.getHours();
      let timeRange = '';
  
      if (currentHour >= 0 && currentHour < 11) {
        timeRange = 'Morning';
      } else if (currentHour >= 11 && currentHour < 17) {
        timeRange = 'Afternoon';
      } else if (currentHour >= 17 && currentHour < 24) {
        timeRange = 'Evening';
      }
  
      const dialogShown = localStorage.getItem(`dialogShown_${timeRange}`);
      if (!showSnackInsights && !dialogShownRecently && !dialogShown) {
        determineGreeting();
        setShowSnackInsights(true);
        setDialogShownRecently(true);
        localStorage.setItem(`dialogShown_${timeRange}`, 'true');
        setTimeout(() => setDialogShownRecently(false), 60000); // Reset after 1 minute
      }
    };
  
    checkTimeAndShowDialog(); // Show dialog when the dashboard is loaded
  
    const intervalId = setInterval(() => {
      checkTimeAndShowDialog();
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, [showSnackInsights, dialogShownRecently]);

  useEffect(() => {
    const resetDialogShown = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      if (currentHour === 0 && currentMinute === 0) {
        localStorage.removeItem('dialogShown_Morning');
        localStorage.removeItem('dialogShown_Afternoon');
        localStorage.removeItem('dialogShown_Evening');
      }
    };
  
    const resetIntervalId = setInterval(resetDialogShown, 60000); // Check every minute
  
    return () => clearInterval(resetIntervalId);
  }, []);

  // Figure out what kind of data we need to grab 

  const { 
    tabOneMainConnectionsMetricsData,
    tabOneSubConnectionsMetricsData,
    tabTwoMainConnectionsMetricsData,
    tabTwoSubConnectionsMetricsData,
  } = useWeekMetrics(allLocations, userProfile.user_id, isLocationsSuccess);

  const demographicsDataTabOne = calculateHighestAndLowestGenderGroups(tabOneMainConnectionsMetricsData?.metricsData);
  const demographicsDataTabTwo = calculateHighestAndLowestGenderGroups(tabTwoMainConnectionsMetricsData?.metricsData);

  const { guestConnectionsChartData: insightsDayTimeDataTabOne } = tabOneMainConnectionsMetricsData || {};
  const { guestConnectionsChartData: insightsDayTimeDataTabTwo } = tabTwoMainConnectionsMetricsData || {};
  const dateTimeConnectionDataTabOne = getDateTimeConnectionsData(insightsDayTimeDataTabOne, "week");
  const dateTimeConnectionDataTabTwo = getDateTimeConnectionsData(insightsDayTimeDataTabTwo, "day");

  const data = {
    tabOne: {
      guestConnect: {
        current: tabOneMainConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
        previous: tabOneSubConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
      },
      uniqueConnect: {
        current: tabOneMainConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
        previous: tabOneSubConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
      },
      demo: {
        largest: `${demographicsDataTabOne.highestGender ?? undefined}, aged ${demographicsDataTabOne.highestGenderAgeGroup ?? undefined}`,
        largestCount: demographicsDataTabOne.highestGenderCount ?? 0,
        secondLargest: `${demographicsDataTabOne.secondHighestGender ?? undefined}, aged ${demographicsDataTabOne.secondHighestGenderAgeGroup ?? undefined}`,
        secondLargestCount: demographicsDataTabOne.secondHighestGenderCount ?? 0,
      },
      busy: {
        time: dateTimeConnectionDataTabOne.highestConnectionsPeriod ?? undefined,
        unique: dateTimeConnectionDataTabOne.totalConnectionsOnPeriod ?? undefined,
        average: dateTimeConnectionDataTabOne.averageConnectionsPerPeriod ?? undefined,
      }
    },
    tabTwo: {
      guestConnect: {
        current: tabTwoMainConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
        previous: tabTwoSubConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
      },
      uniqueConnect: {
        current: tabTwoMainConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
        previous: tabTwoSubConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
      },
      demo: {
        largest: `${demographicsDataTabTwo.highestGender ?? undefined}, aged ${demographicsDataTabTwo.highestGenderAgeGroup ?? undefined}`,
        largestCount: demographicsDataTabTwo.highestGenderCount ?? 0,
        secondLargest: `${demographicsDataTabTwo.secondHighestGender ?? undefined}, aged ${demographicsDataTabTwo.secondHighestGenderAgeGroup ?? undefined}`,
        secondLargestCount: demographicsDataTabTwo.secondHighestGenderCount ?? 0,
      },
      busy: {
        time: dateTimeConnectionDataTabTwo.highestConnectionsPeriod ?? undefined,
        unique: dateTimeConnectionDataTabTwo.totalConnectionsOnPeriod ?? undefined,
        average: dateTimeConnectionDataTabTwo.averageConnectionsPerPeriod ?? undefined,
      }
    }
  };

  const allMetricsReceived = (
    data.tabOne.guestConnect.current !== undefined &&
    data.tabOne.guestConnect.previous !== undefined &&
    data.tabOne.uniqueConnect.current !== undefined &&
    data.tabOne.uniqueConnect.previous !== undefined &&
    data.tabOne.demo.largest !== undefined &&
    data.tabOne.demo.largestCount !== undefined &&
    data.tabOne.demo.secondLargest !== undefined &&
    data.tabOne.demo.secondLargestCount !== undefined &&
    data.tabOne.busy.time !== undefined &&
    data.tabOne.busy.unique !== undefined &&
    data.tabOne.busy.average !== undefined &&
    data.tabTwo.guestConnect.current !== undefined &&
    data.tabTwo.guestConnect.previous !== undefined &&
    data.tabTwo.uniqueConnect.current !== undefined &&
    data.tabTwo.uniqueConnect.previous !== undefined &&
    data.tabTwo.demo.largest !== undefined &&
    data.tabTwo.demo.largestCount !== undefined &&
    data.tabTwo.demo.secondLargest !== undefined &&
    data.tabTwo.demo.secondLargestCount !== undefined &&
    data.tabTwo.busy.time !== undefined &&
    data.tabTwo.busy.unique !== undefined &&
    data.tabTwo.busy.average !== undefined
  );

  // 1. Decide which chart types we want to show
  let chartTypesToShow = [];

  if (selectedDateRangeInDays === 1) {
    // Show only timeOfDay
    chartTypesToShow = ["timeOfDay"];
  } else if (selectedDateRangeInDays > 1 && selectedDateRangeInDays < 62) {
    // Show day, timeOfDay, dayOfWeek
    chartTypesToShow = ["day", "timeOfDay", "dayOfWeek"];
  } else if (selectedDateRangeInDays >= 62) {
    // Show month, day, timeOfDay, dayOfWeek
    chartTypesToShow = ["month", "day", "timeOfDay", "dayOfWeek"];
  }
                            
  return (
    <Flex className="content-container" direction="column" gap="5"
      style={{alignSelf: 'stretch', width: '100%', height: '100%', alignItems: 'center', flex: '1 0 0'}}>
      {/* Filter Section */}
      <Flex className="filter-bar" style={{alignSelf: "stretch"}} display="flex" justify="end" align="center" gap="4">
        {userProfile.user_first_name && allMetricsReceived && (
          <SnackInsights
            show={showSnackInsights}
            onClose={() => setShowSnackInsights(false)}
            greeting={greeting}
            firstName={userProfile.user_first_name}
            data={data}
          />
        )}
        <DatePickerWithRange 
          value={selectedDateRange} 
          onDateChange={setSelectedDateRange}
          disableBeforeDate={metricsData?.disable_before_date}
        />
        <LocationMultiSelect
          options={initialItems}
          defaultValue={locationSelection}
          onValueChange={setLocationSelection}
        />
      </Flex>
      {/* {locationSelection.length === 0 ? (
        <Flex display="flex" direction="column" align="center" justify="center" gap="2" height="70vh">
          <Text size="5" weight="medium">
            No Location Selected
          </Text>
          <Text size="2" weight="regular">
            Select a Location to see results
          </Text>
        </Flex>
      ) : ( */}
      {/* Analytics Section */}
      <Flex direction="column" gap="5" style={{alignSelf: 'stretch', flex: '1 0 0', alignItems: 'center',
        overflowY: 'scroll'}}>
        {/* Metric Cards */}
        <Flex className="card-container" style={{alignSelf: "stretch"}} align="start" gap="5">
          {metricTileDataOne.map((entry, index) => (
            <MetricCard key={index}
              metricName={entry.name}
              metricIcon={entry.icon}
              metric={entry.metric}
              subMetric={entry.subMetric}
              updates={entry.updates}
              tooltip={entry.tooltip}
            />
          ))}
        </Flex>
      {/* )} */}
        {/* Charts */}
        <Flex direction="column" gap="5" style={{alignSelf: 'stretch', flex: '1 0 0', alignItems: 'center'}}>
          {chartTypesToShow.map((chartType) => {
            const { text, tooltip, ChartComponent } = chartConfig[chartType];

            return (
              <ChartCard
                key={chartType}
                text={text}
                tooltip={tooltip}
                legend={
                  <NewReturningLegend 
                    payload={[
                      { value: 'new', color: 'var(--grass-9)' },
                      { value: 'returning', color: 'var(--amber-9)' },
                    ]}
                  />
                }
              >
                <ChartComponent
                  data={guestConnectionsChartData}
                  startTimeMilliseconds={selectedDateRange.from.getTime()}
                  endTimeMilliseconds={selectedDateRange.to.getTime()}
                />
              </ChartCard>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
//     <React.Fragment>
//       <div style={{backgroundColor: "var(--color-background)" }} >
//       <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "var(--color-panel-solid)" }}>
//         <Header >
//           <Flex display="flex" align="center" gap="4">
//             <Text className="" size="4" weight="bold">
//               Guest Wi-Fi
//             </Text>
//             <Tooltip content="This product is still in development and so you may encounter issues.">
//               <Badge variant="soft" size="1" color="gray">
//                 Beta
//               </Badge>
//             </Tooltip>
//             <Flex display="flex" align="center" gap="5">
//               {userRole === 'Owner' && (
//                 <Button size="2" variant="ghost" color="gray" style={{cursor: "pointer", marginLeft:"auto"}}
//                   onClick={() => navigate("/guest-wifi-settings")}>
//                   <GearIcon />
//                   Settings
//                 </Button>
//               )}
//             </Flex>
//           </Flex>
//           <Flex display="flex" align="center" gap="4">
//             <div>
//               <LiveConnectionButton 
//                 onClick={toggleLiveConnectionCards}
//                 showLiveConnectionCards={showLiveConnectionCards} />
//             </div>
//             {/* <div>
//               <DeviceGroupingSwitch />
//             </div> */}
//           </Flex>
//         </Header>
//         <Separator size="4" orientation="horizontal" />
//         <Flex
//           style={{
//             alignSelf: "stretch",
//             position: "sticky",
//             top: "0px",
//             zIndex: 1000,
//             backgroundColor: "var(--color-panel-solid)",
//           }}
//           display="flex"
//           align="start"
//           direction="row"
//           width="100%"
//         >
//           <div
//             style={{
//               maxHeight: showLiveConnectionCards ? "500px" : "0px",
//               overflow: "hidden",
//               transition: "max-height 0.8s ease-in-out",
//               display: "flex",
//               flexDirection: "row",
//               width: "100%",
//             }}
//           >
//             {showLiveConnectionCards && liveConnectionStat.map((entry, index) => (
//               <LiveStatCard
//                 key={index}
//                 title={entry.title}
//                 value={entry.value}
//                 subValue={entry.subValue}
//                 separator={entry.separator}
//                 badge={entry.badge}
//                 isLast={index === liveConnectionStat.length - 1} 
//                 userRole={userRole}
//               />
//             ))}
//           </div>
//         </Flex>
//         {showLiveConnectionCards && <Separator size="4" orientation="horizontal" />}
//       </div>
//       {locationSelection.length === 0 ? (
//         <Flex
//           display="flex"
//           direction="column"
//           align="center"
//           justify="center"
//           height="80vh"
//           gap="2"
//         >
//           <Text
//             size="5"
//             weight="medium"
//             style={{
//               color: 'var(--gray-12)',
//             }}
//           >
//             No Location Selected
//           </Text>
//           <Text
//             size="2"
//             weight="regular"
//             style={{
//               color: 'var(--gray-12)',
//             }}
//           >
//             Select a Location to see results
//           </Text>
//         </Flex>
//       ) : (
//         <React.Fragment>
//           <Flex
//             display="flex"
//             direction="column"
//             align="center"
//             gap="5"
//             p="5"
//           >
//             <Flex
//               display="flex"
//               height="180px"
//               width='100%'
//               maxWidth="1000px"   
//               gap="5"
//               direction="row"
//               justify="center"
//             >
//               {metricTileDataOne.map((entry, index) => (
//                 <MetricCard key={index}
//                   metricName={entry.name}
//                   metricIcon={entry.icon}
//                   metric={entry.metric}
//                   subMetric={entry.subMetric}
//                   updates={entry.updates}
//                   tooltip={entry.tooltip}
//                 />
//               ))}
//             </Flex>
//             {selectedDateRangeInDays === 1 && (
//         <ChartCard 
//           text="Guest connections by time of day" 
//           tooltip="Breakdown of guest connections over time."
//           legend={<NewReturningLegend payload={[
//             { value: 'new', color: "var(--grass-9)" },
//             { value: 'returning', color: "var(--amber-9)" }
//           ]} />}
//         >
//           <GuestConnectionsByTimeOfDayChart 
//             data={guestConnectionsChartData} 
//             startTimeMilliseconds={selectedDateRange.from.getTime()} 
//             endTimeMilliseconds={selectedDateRange.to.getTime()} 
//           />
//         </ChartCard>
//       )}

// {selectedDateRangeInDays > 1 && selectedDateRangeInDays < 62 && (
//   <React.Fragment>
//     <ChartCard 
//       text="Guest connections by day" 
//       tooltip="Breakdown of guest connections by day."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsByDayChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//     <ChartCard 
//       text="Guest connections by time of day" 
//       tooltip="Breakdown of guest connections over time of day."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsByTimeOfDayChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//     <ChartCard 
//       text="Guest connections by day of the week" 
//       tooltip="Breakdown of guest connections over day of week."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsByDayOfWeekChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//   </React.Fragment>
// )}

// {selectedDateRangeInDays >= 62 && (
//   <React.Fragment>
//         <ChartCard 
//       text="Guest connections by month" 
//       tooltip="Breakdown of guest connections by month."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsByMonthChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//     <ChartCard 
//       text="Guest connections by day" 
//       tooltip="Breakdown of guest connections by day."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsByDayChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//     <ChartCard 
//       text="Guest connections by time of day" 
//       tooltip="Breakdown of guest connections over time of day."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsByTimeOfDayChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//     <ChartCard 
//       text="Guest connections by day of the week" 
//       tooltip="Breakdown of guest connections over day of week."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsByDayOfWeekChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//   </React.Fragment>
// )}
  
//             {/* {selectedDateRangeInDays > 1 && selectedDateRangeInDays < 62 && (
//     <ChartCard 
//       text="Guest Connections" 
//       tooltip="Breakdown of guest connections over time."
//       legend={<NewReturningLegend payload={[
//         { value: 'new', color: "var(--grass-9)" },
//         { value: 'returning', color: "var(--amber-9)" }
//       ]} />}
//     >
//       <GuestConnectionsChart 
//         data={guestConnectionsChartData} 
//         startTimeMilliseconds={selectedDateRange.from.getTime()} 
//         endTimeMilliseconds={selectedDateRange.to.getTime()} 
//       />
//     </ChartCard>
//   )} */}
//             <Flex
//               display="flex"
//               height="180px"
//               width='100%'
//               maxWidth="1000px"
//               gap="5"
//               direction="row"
//               justify="center"
//             >
//               {metricTileDataTwo.map((entry, index) => (
//                 <MetricCard key={index}
//                   metricName={entry.name}
//                   metricIcon={entry.icon}
//                   metric={entry.metric}
//                   updates={entry.updates}
//                   tooltip={entry.tooltip}
//                 />
//               ))}
//             </Flex>
//               {initialItems.length > 1 && locationSelection.length > 1 ? (
//                 <>
//                   <ChartCard text="Guest connections by location" 
//                     tooltip="Breakdown of guest connections over time, by location."
//                     legend={<NewReturningLegend payload={[
//                       { value: 'new', color: "var(--grass-9)" },
//                       { value: 'returning', color: "var(--amber-9)" }
//                     ]} />}
//                   >
//                     <GuestConnectionsByLocationsChart data={guestConnectionsByLocationsChartData}/>
//                   </ChartCard>
//                 </>
//             ): null} 
//             {/* <ChartCard text="Guest Connections by Location" tooltip="Trends of guest connections by location.">
//               <LineChart />
//             </ChartCard>
//             <ChartCard text="Guest Connections Heatmap" tooltip="Guest connection density comparison over periods.">
//               <HeatmapChart />
//             </ChartCard> */} 
//               <Flex
//                     display="flex"
//                     direction="row"
//                     align="start"
//                     gap="5"
//                     width="100%"
//                     maxWidth="1000px"
//                   >
//                   <ChartCard text="Unique guests by age" 
//                     tooltip="Breakdown of unique guests by age."
//                   >
//                     <UniqueGuestsByAgeChart metricsData={metricsData}/>
//                   </ChartCard>
//                   <ChartCard text="Unique guests by gender" 
//                     tooltip="Breakdown of unique guests by gender."
//                   >
//                     <UniqueGuestsByGenderChart metricsData={metricsData}/>
//                   </ChartCard>
//                   </Flex>
//           </Flex>
//         </React.Fragment>
//       )}
//       </div>
//     </React.Fragment>
  );
}
