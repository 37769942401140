import React, { useState, useEffect } from 'react';
import { Flex, Card, Table } from "@radix-ui/themes";
import { OrgUser } from './OrgUser';
import { OrgInvite } from './OrgInvite';
import { useGetOrgUsers, useEditOrgUser, useDeleteOrgUser, useGetInviteUsers, useResendInviteUser, useDeleteInviteUser } from "hooks";

const Users = ({viewerId}) => {


  const { data: orgUser, refetch: refetchOrgUsers } = useGetOrgUsers(viewerId);
  const orgUserList = orgUser?.map((entry) => ({
    id: entry.user.id,
    email: entry.user.email,
    first_name: entry.user.first_name,
    last_name: entry.user.last_name,
    user_role: entry.user.user_role,
    locations: entry.locations,
  }));

  const { data: invitedUser, refetch: refetchInvitedUsers } = useGetInviteUsers();
  const invitedUserList = invitedUser?.map((entry) => entry);

  const { mutate: editUser } = useEditOrgUser();
  const { mutate: deleteUser } = useDeleteOrgUser();

  const handleOrgUserChange = (value, orgUserId, type) => {
    if (value != null && (typeof value !== 'string' || value.trim() !== "")) {
      editUser({
        owner_id: viewerId,
        data: { id: orgUserId, [type]: value }
      });
    }
  };

  const handleOrgUserDelete = (viewerId, orgUserId) => {
    deleteUser({
      owner_id: viewerId,
      data: { id: orgUserId }
    });
  };

  const { mutate: resendInvite } = useResendInviteUser();
  const handleOrgInviteResend = (email, token) => {
    let payload = { email: email, token: token }
    resendInvite(payload);
  };

  const { mutate: deleteInvite } = useDeleteInviteUser();
  const handleOrgInviteDelete = (token) => {
    let payload = { token: token }
    deleteInvite(payload);
  };


    return (
    <Flex className="card_container" style={{ alignSelf: "stretch"}} display="flex" p="5" direction="column" align="center" gap="5" flexGrow="1" flexShrink="0" flexBasis="0">
  <Card style={{
    width: "1000px",
    padding: "var(--space-4)",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "var(--space-4)",
    borderRadius: "var(--radius-2)",
    flexGrow: "1",
    height: "calc(100vh - 160px)"
  }}>
    <Table.Root style={{
      width: "968px",
      flexGrow: "1",
      display: "flex",
      flexDirection: "column",
      height: "100%"
    }}>
      <Table.Header style={{ flexShrink: "0", position: "sticky", zIndex:1, top:0, backgroundColor: 'var(--color-panel-solid)'}}>
        <Table.Row>
          <Table.ColumnHeaderCell width="283px" style={{ fontWeight: 500 }}>Name</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell width="283px" style={{ fontWeight: 500 }}>Role</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell width="283px" pl="5" style={{ fontWeight: 500 }}>Locations</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body style={{ flexGrow: "1"}}>
        {orgUserList
          ?.sort((a, b) => {
            const firstNameComparison = (a.first_name || "").localeCompare(b.first_name || "");
            if (firstNameComparison !== 0) {
              return firstNameComparison;
            }
            return (a.last_name || "").localeCompare(b.last_name || "");
          })
          .map((entry, index) => (
            <OrgUser
              key={index}
              orgUserId={entry.id}
              viewerId={viewerId}
              entry={entry}
              handleOrgUserChange={handleOrgUserChange}
              handleOrgUserDelete={handleOrgUserDelete}
            />
          ))}
        {invitedUserList
          ?.sort((a, b) => (a.email || "").localeCompare(b.email || ""))
          .map((entry, index) => (
            <OrgInvite 
              key={index}
              email={entry.email}
              token={entry.token}
              role={entry.invitation_type}
              locations={entry.locations_list}
              handleOrgInviteResend={handleOrgInviteResend}
              handleOrgInviteDelete={handleOrgInviteDelete}
              viewerId={viewerId}
            />
          ))}
      </Table.Body>
    </Table.Root>
  </Card>
</Flex>
  );
};

export default Users;