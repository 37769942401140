import { useState, useEffect, createContext, useContext } from 'react';
import { useProfile } from "hooks";

const ProfileContext = createContext();

export function ProfileProvider({ children }) {

  const { data: profile } = useProfile();

  const [userProfile, setUserProfile] = useState({
    user_id: null,
    organisation_id: null,
    user_role: null,
    user_first_name: null
  });

  useEffect(() => {
    if (profile) {
      setUserProfile({
        user_id: profile.id,
        organisation_id: profile.organisation_id,
        user_role: profile.user_role,
        user_first_name: profile.first_name
      });
    }
  }, [profile]);

  return ( 
    <ProfileContext.Provider value={{ userProfile }}>
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfileContext() {
  return useContext(ProfileContext);
}