import React, {useEffect, useState} from 'react';
import { useLocations } from "hooks";
import { Flex, Text, Button, Table, Avatar, Select, DropdownMenu, Badge, AlertDialog} from "@radix-ui/themes";
import { PersonIcon, DotsHorizontalIcon} from "@radix-ui/react-icons";
import { LocationMultiSelect } from "components";
import { getProfile } from "api/UsersApi";

export function OrgUser({ entry, orgUserId, viewerId, handleOrgUserChange, handleOrgUserDelete }) {

  const { initialItems, setLocationSelection } = useLocations(viewerId);
  // console.log("locations", entry.locations);

  const handleLocationClick = (value) => {
    setLocationSelection(value);
    handleOrgUserChange(value, orgUserId, "locations");
  }

  const handleUserRoleClick = (value) => handleOrgUserChange(value, orgUserId, "role");

  const handleCustomUserDelete = (e) => {
    handleOrgUserDelete(viewerId, orgUserId);
  }

  const [profileEmail, setProfileEmail] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getProfile();
        setProfileEmail(profile.data.email);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
  
    fetchProfile();
  }, []);


  return (
    <Table.Row align="center">
      <Table.Cell 
        p="3"
        minWidth="160px"
        maxWidth="283px"
        style={{
          display: 'flex',
          minHeight: '68px',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <Avatar variant="solid" size="2" fallback={(entry.first_name !== null && entry.last_name !== null) ? `${entry.first_name.slice(0, 1)}${entry.last_name.slice(0, 1)}`: <PersonIcon />} color="blue" />
        <Flex className="text-container" display="flex" direction="column" justify="center" align="start" gap="var(--space-1)" flexGrow="0" flexShrink="0" flexBasis="0">
          <Text weight="regular" size="2"
            style={{ 
              whiteSpace: "nowrap", 
              overflow: "hidden", 
              textOverflow: "ellipsis",
              maxWidth: "200px" 
            }}
            >
            {`${entry.first_name} ${entry.last_name}`}
          </Text>
          <Text style={{
            color: "var(--gray-a11)",
            whiteSpace: "nowrap", 
            overflow: "hidden", 
            textOverflow: "ellipsis", 
            maxWidth: "200px"
            }} 
            weight="regular" 
            size="1">
            {entry.email}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell  
        p="3"
        minWidth="160px"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <Select.Root 
          size="2" 
          defaultValue={`${entry.user_role}`.toLowerCase()} 
          onValueChange={handleUserRoleClick}
          disabled={entry.email === profileEmail}
        >
          <Select.Trigger variant="ghost" color="gray" style={{width: "100%"}}/>
          <Select.Content>
            <Select.Group>
              <Select.Item value="owner">Owner</Select.Item>
              <Select.Item value="manager">Manager</Select.Item>
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </Table.Cell>
      <Table.Cell
        p="3"
        size="2"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
          alignSelf: "stretch"
        }}
      >
        {entry.locations.length === 0 ? 
        <span style={{ paddingLeft: '12px', color: 'var(--gray-a11)' }}>All Locations</span> :
          <LocationMultiSelect
            noShowBorder={true}
            toggleFlexGrow={true}
            options={initialItems}
            defaultValue={entry.locations}
            onValueChange={handleLocationClick}
          />
        }
      </Table.Cell>
      <Table.Cell justify="end">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
          {entry.email !== profileEmail ? (
            <Button variant='ghost' color='gray'>
              <DotsHorizontalIcon />
            </Button>
          ) : (
            <span />
          )}
          </DropdownMenu.Trigger>
            <DropdownMenu.Content
              size="2"
              variant="solid"
              style={{
                position: 'absolute',
                top: '0',
                left: '100%',
                display: "flex",
                marginLeft: '-120px',
                justifyContent: 'space-between', 
                alignItems: 'center',
                width: '151px',
              }}          
            >
              <AlertDialog.Root>
              <AlertDialog.Trigger>
                <DropdownMenu.Item color="red" style={{cursor:"pointer"}} onSelect={(event) => event.preventDefault()}>
                  Delete User
                </DropdownMenu.Item>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="402px" maxHeight="156px">
                <AlertDialog.Title>Delete User</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  This action cannot be undone.
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button onClick={handleCustomUserDelete} variant="solid" color="red">
                      Delete
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Table.Cell>
    </Table.Row>
  );
}