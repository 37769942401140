import { apiURL, encryptionKey, insightsURL, bChatURL, bIntegrationsURL } from "../helpers/config";

const params = {
  apiUrl: apiURL,
  insightsUrl: insightsURL,
  bChatUrl: bChatURL,
  bIntegrationsUrl: bIntegrationsURL,
  encryptionKey: encryptionKey,
};

export default params;
