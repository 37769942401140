import React, {useState, useEffect} from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/common/PrivateRoute";

// Import your components without lazy loading
import Login from "./pages/login/Login";
import Registration from "./pages/singup/Registration";
import Setup from "./pages/singup/Setup";
import Resetpassword from "./pages/resetpassword/Resetpassword";
import Changepassword from "./pages/changepassword/Changepassword";
import Logout from "./pages/logout/Logout";
// import InsightsLayout from "./pages/insights/InsightsLayout";
import { MainLayout, WifiLayout, IntegrationsLayout, OrganisationLayout } from "layout";
import Dashboard from "./pages/dashboard/Dashboard";
// import Insights from "./pages/insights/Insights";
// import FootfallInsights from "pages/insights/FootfallInsights";
// import ExternalImpactInsights from "pages/insights/ExternalImpactInsights";
// import IndustryNewsInsights from "pages/insights/industryNewsInsights/IndustryNewsInsights";
// import AllFootfallInsights from "pages/insights/AllFootfallInsights";
// import AllExternalImpactInsights from "pages/insights/AllExternalImpactInsights";
// import AllIndustryNewsInsights from "pages/insights/industryNewsInsights/AllIndustryNewsInsights";
import BChat from "pages/bChat/bChat";
import Account from "./pages/account/Account";
import AuthProvider from "components/common/AuthProvider";
import PublicRoute from "components/common/PublicRoute";
import Locations from "pages/locations/Locations";
import GuestPortal from "pages/guestPortal/GuestPortal";
import Networks from "pages/networks/Networks";
import OrganisationLocations from "pages/organisation/OrganistionLocations";
import Team from "pages/organisation/Team";
import FirstTimeLogin from "./pages/firstTimeLogin/FirstTimeLogin";
import NotFound from "pages/notFound/NotFound";
import { isMobile } from 'react-device-detect';
import NotAvailableOnMobile from "pages/notAvailable/NotAvailableOnMobile";
import ActiveIntegrations from "pages/integrations/ActiveIntegrations";
import BrowseIntegrations from "pages/integrations/BrowseIntegrations";
import SignUpSuccessful from "pages/singup/SignUpSuccessful";
import { getProfile } from "api/UsersApi";
// import { R } from "@tanstack/react-query-devtools/build/legacy/devtools-PtxSnd7z";
import Customers from "pages/customers/Customers";

export default function RouteManager() {

  const [userRoleForAccess, setUserRoleForAccess] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (userRoleForAccess === null) {
      const fetchProfile = async () => {
        try {
          const profile = await getProfile();
          setUserRoleForAccess(profile.data.user_role);
        } catch (error) {
          console.error("Error fetching profile:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, [userRoleForAccess]);

  if (loading) {
    return <div></div>;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {isMobile ? (
            <Route path="*" element={<NotAvailableOnMobile />}/>
          ) : (
            <React.Fragment>
              <Route element={<PublicRoute />}>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/forgot-password" element={<Resetpassword />} />
                <Route path="/password-reset-confirm" element={<Changepassword />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/setup" element={<Setup />} />
                <Route path="/signup-successful" element={<SignUpSuccessful />}/>
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route index path="/" element={<Navigate to="/chat" />} />
                <Route path="/" element={<MainLayout />}>
                  {/* ChatF&B */}
                  <Route path="/chat" element={<BChat />} />
                  {/* WiFi Pages */}
                  <Route path="/wifi" element={<WifiLayout />}>
                    <Route index element={<Navigate to="analytics" />} />
                    <Route path="analytics" element={<Dashboard />} />
                    <Route path="locations" element={userRoleForAccess === 'Manager' ? <NotFound /> : <Locations />} />
                    <Route path="guest-portal" element={userRoleForAccess === 'Manager' ? <NotFound /> : <GuestPortal />} />
                    <Route path="networks" element={userRoleForAccess === 'Manager' ? <NotFound /> : <Networks />} />
                    <Route path="first-time-login" element={<FirstTimeLogin />} />
                    <Route path="account" element={<Account />} />
                  </Route>
                  {/* Customers Page */}
                  <Route path="/customers" element={<Customers />} />
                  {/* Integrations Pages */}
                  <Route path="/integrations" element={<IntegrationsLayout />}>
                    <Route index element={<Navigate to="active" />} />
                    <Route path="active" element={<ActiveIntegrations />}/>
                    <Route path="browse-all" element={<BrowseIntegrations />}/>
                  </Route>
                  {/* Organisation Pages */}
                  <Route path="/organisation" element={userRoleForAccess === 'Manager' ? <NotFound /> : <OrganisationLayout />}>
                    <Route index element={<Navigate to="locations" />} />
                    <Route path="locations" element={<OrganisationLocations />}/>
                    <Route path="team" element={<Team />}/>
                  </Route>
                </Route>
                {/* <Route path="/insights" element={<InsightsLayout />}>
                  <Route index element={<Insights/>}/>
                  <Route path="footfall" element={<FootfallInsights />}/>
                  <Route path="footfall/show-all" element={<AllFootfallInsights />}/>
                  <Route path="external-insights" element={<ExternalImpactInsights />}/>
                  <Route path="external-insights/show-all" element={<AllExternalImpactInsights />}/>
                  <Route path="industry-news" element={<IndustryNewsInsights />}/>
                  <Route path="industry-news/show-all" element={<AllIndustryNewsInsights />}/>
                </Route> */}
              </Route>
              <Route path="*" element={<NotFound />} /> {/* Catch-all route for undefined paths */}
            </React.Fragment>
          )}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
