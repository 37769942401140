import React from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { ActiveUnifiIntegrations } from './unifi/ActiveUnifiIntegrations';
import { ActiveBconnectIntegrations } from './bconnect/ActiveBconnectIntegrations';
import { useLocationContext } from 'context';

export default function ActiveIntegrations() {
  const { initialItems } = useLocationContext();
  const noIntegrations = false;

  return (
    noIntegrations ? (
      <Flex className="frame-528173501" style={{alignSelf: "stretch", borderRadius: "var(--space-2)", border: "1px dashed var(--gray-a6)"}} display="flex" height="calc(100vh - 185px)" minHeight="0" direction="column" justify="center" align="center" gap="5" flexGrow="1" >
        <Flex className="frame-528173502" display="flex" direction="column" align="center" gap="2">
          <Text size="4" weight="medium">
            No Active Integrations
          </Text>
          <Text size="2" weight="regular">
            After you activate and set up integrations, they'll show up here for you to manage.
          </Text>
        </Flex>
        <Button size="2" variant="soft" color="gray">
          Browse integrations
        </Button>
      </Flex>
    ) : (
      <Flex className="frame-528173401" display="flex" direction="column" align="end" gap="5" flexGrow="1">
        <ActiveUnifiIntegrations />
        <ActiveBconnectIntegrations />
      </Flex>
    )
  );
}