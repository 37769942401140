import * as React from "react";
import { Text, Flex, Button, Checkbox, CheckboxGroup, Popover, Badge } from "@radix-ui/themes";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export function MultiSelect({ options, onValueChange, defaultValue, placeholder }) {
  const [selectedValues, setSelectedValues] = React.useState(defaultValue);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [display, setDisplay] = React.useState(placeholder);
  const [topCheckBoxBool, setTopCheckBoxBool] = React.useState(true);

  React.useEffect(() => {
    updateDisplay(defaultValue);
  }, []);

  const updateDisplay = (newSelectedValues) => {
    setDisplay(
      newSelectedValues.length === 0
        ? `Select ${placeholder.toLowerCase()}`
        : newSelectedValues.length === options.length
        ? placeholder
        : (
          <Flex align="center" gap="1">
            {placeholder} <Badge size="1" color="blue" variant="solid">{newSelectedValues.length}</Badge>
          </Flex>
        )
    );
    setTopCheckBoxBool(newSelectedValues.length === options.length);
  };

  const handleClear = () => {
    const newValues = [];
    setSelectedValues(newValues);
    onValueChange(newValues);
    updateDisplay(newValues);
    setTopCheckBoxBool(false);
  };

  const toggleAll = (checked) => {
    if (!checked) {
      handleClear();
    } else {
      const allValues = options.map((option) => option.value);
      setSelectedValues(allValues);
      onValueChange(allValues);
      updateDisplay(allValues);
      setTopCheckBoxBool(true);
    }
  };

  const handleValueChange = (newValues) => {
    setSelectedValues(newValues);
    onValueChange(newValues);
    updateDisplay(newValues);
    setTopCheckBoxBool(newValues.length === options.length);
  };

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger asChild>
        <Button
          size="2"
          variant="soft"
          color="gray"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <Text color="gray" weight="medium">
            {display}
          </Text>
          <ChevronDownIcon style={{ color: "var(--gray-11)" }} />
        </Button>
      </Popover.Trigger>

      <Popover.Content style={{ width: "200px", padding: "0" }}>
        <Flex direction="column" gap="1">
          <Flex p="2">
            <Checkbox
              size="2"
              checked={topCheckBoxBool}
              onCheckedChange={toggleAll}
            />
            <Text size="2" style={{ marginLeft: "8px" }}>Select All</Text>
          </Flex>
          <CheckboxGroup.Root
            size="2"
            value={selectedValues}
            onValueChange={handleValueChange}
          >
            {options.map((option) => (
              <Flex key={option.value} p="2">
                <CheckboxGroup.Item value={option.value}>
                  {option.label}
                </CheckboxGroup.Item>
              </Flex>
            ))}
          </CheckboxGroup.Root>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}