import instance from './BasebIntegrationsApi';

export function attemptAuthenticateIntegrations(data) {
  return instance.request({
    url: `auth/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data
  });
}

export function attemptUpdateIntegrations(data) {
  return instance.request({
    url: `update/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data
   });
}
    
export function createMailchimpAudienceFields(data) {
  return instance.request({
    url: `mailchimp/create-fields/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data
  });
}

export function validateMailchimpCredentials(data) {
  return instance.request({
    url: `mailchimp/validate/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data
  });
}

export function getMailchimpAudiences(data) {
  return instance.request({
    url: `mailchimp/audiences/`,
    method: "POST",
    withCredentials: false, 
    headers: {
      Accept: "application/json", 
      "Content-Type": "application/json",
    },
    data: data
  });
}

export function getMailchimpContactFields(audienceId, organisationId) {
  return instance.request({
    url: `mailchimp/audiences/${audienceId}/fields/?organisation_id=${organisationId}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  });
}

export function mapMailchimpFields(audienceId, data) {
  return instance.request({
    url: `mailchimp/audiences/${audienceId}/field-mapping/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data
  });
}

export function syncGuestDataWithMailchimp(audienceId, data) {
  return instance.request({
    url: `mailchimp/audiences/${audienceId}/sync-guests/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data
  });
}

export function getActiveIntegrations(organisationId) {
  return instance.request({
    url: `organisation/integrations/?organisation_id=${organisationId}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  });
}