import { useState, useEffect } from 'react';
import { validateUnifiControllerUrl } from 'components/utils/validationUtils';
import { BrowseIntegrateCard } from 'components';
import { useLocationContext } from 'context';
import { Flex, Text, TextField, IconButton, Select, Button } from '@radix-ui/themes';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { useAuthenticateIntegrations, useUpdateIntegrations } from 'hooks';
import { useQueryClient } from '@tanstack/react-query'; // Import useQueryClient

export function BrowseUnifiIntegrations({ userProfile }) {
  const { initialItems } = useLocationContext();

  // States and hooks. validIntegrationDetails is set to true on load to stop error messages from rendering!
  const queryClient = useQueryClient();
  const [payloadOne, setPayloadOne] = useState({
    unifi_controller_url: null,
    username: null,
    password: null
  })
  const [payloadTwo, setPayloadTwo] = useState([{ location: '', site: '' }]);
  const [openDialog, setOpenDialog] = useState({
    auth: false,
    detail: false
  })
  const [validAuthDetails, setValidAuthDetails] = useState({
    unifi_url_valid: true,
    username_valid: true,
    password_valid: true
  });
  const authenticateIntegrations = useAuthenticateIntegrations();
  const updateIntegrations = useUpdateIntegrations();

  const handleOpenChange = (dialog_type, isOpen) => {
    setOpenDialog({
      ...openDialog,
      [dialog_type]: isOpen
    });
    if (!isOpen) {
      handleCancelAuthDialog();
      handleCancelDetailDialog();
    }
  }

  const handleTextFieldChange = (key) => {
    return (event) => {
      setPayloadOne({
        ...payloadOne, 
        [key]: event.target.value
      })
    }
  }
  const handleDropdownFieldChange = (index, field) => {
    return (value) => {
      const newPayloadTwo = [...payloadTwo];
      newPayloadTwo[index][field] = value;
      setPayloadTwo(newPayloadTwo);

      if (index === newPayloadTwo.length - 1) {
        const { location, site } = newPayloadTwo[index];
        const stillCanAddLocation = newPayloadTwo.length < initialItems.length;

        if (stillCanAddLocation && location && site) {
          newPayloadTwo.push({location: "", site: ""});
          setPayloadTwo(newPayloadTwo);
        }
      }
    }
  }

  const handleCancelAuthDialog = () => {
    setPayloadOne({
      unifi_controller_url: null,
      username: null,
      password: null
    })
    setValidAuthDetails({
      unifi_url_valid: true,
      username_valid: true,
      password_valid: true
    })
  }

  const handleCancelDetailDialog = () => {
    setPayloadTwo([{ location: '', site: '' }]);
  }

  const handleSave = async () => {
    const url = payloadOne.unifi_controller_url;
    const username = payloadOne.username;
    const password = payloadOne.password;

    const isUrlValid = validateUnifiControllerUrl(url);
    const isUsernameValid = !!username;
    const isPasswordValid = !!password;

    if (isUrlValid && isUsernameValid && isPasswordValid) {
      await authenticateIntegrations.mutateAsync({
        timestamp: Date.now(),
        organisation_id: userProfile.organisation_id,
        type: "unifi",
        sub_payload: payloadOne 
      });
      queryClient.invalidateQueries(['authenticate-integrations']);
      handleOpenChange("detail", true);
    } else {
      handleOpenChange("detail", false)
    }
    setValidAuthDetails({
      unifi_url_valid: isUrlValid,
      username_valid: isUsernameValid,
      password_valid: isPasswordValid
    });
  }

  const handleActivate = async () => {
    const transPayloadTwo = Object.fromEntries(
      payloadTwo.map(item => [item.location, item.site])
    );
    await updateIntegrations.mutateAsync({
      timestamp: Date.now(),
      organisation_id: userProfile.organisation_id,
      type: "unifi",
      state: "active",
      sub_payload: transPayloadTwo
    })
    queryClient.invalidateQueries(['authenticate-integrations']);


  }

  const unifiSites = authenticateIntegrations?.data?.data?.data.map((site) => ({name: site.name, desc: site.desc}));

  console.log("Page state", {
    "Payload One": payloadOne,
    "Payload Two": payloadTwo,
    "Dialog open state": openDialog,
    "Response data": authenticateIntegrations?.data?.data?.data
  });

  return (
    <BrowseIntegrateCard.Root name="Unifi" subtitle="Wi-Fi" image_url="unifi.png" status="inactive" openDialog={openDialog} handleOpenChange={handleOpenChange}>
      <BrowseIntegrateCard.AuthDialog 
        dialog_description="The URL could be a local address (e.g., https://192.168.1.100:8443), a remote server (e.g., https://controller.mybusiness.com:8443), or UniFi Cloud (https://unifi.ui.com)."
        onCancel={handleCancelAuthDialog}
      >
        <Flex className="slot-container" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="5">
          <Flex className="controller-url-field" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="1">
            <Text size="2" weight="medium">
              Controller URL
            </Text>
            <TextField.Root onChange={handleTextFieldChange("unifi_controller_url")} style={{alignSelf: "stretch"}} size="2" variant="classic"/>
            {validAuthDetails.unifi_url_valid ? null : <Text size="1" weight="regular" color="red">Input a valid controller URL.</Text>}
          </Flex>
          <Flex className="frame-528173524" style={{alignSelf: "stretch"}} display="flex" align="center" gap="5">
            <Flex className="username-field" display="flex" direction="column" align="start" gap="1" flexGrow="1">
              <Text size="2" weight="medium">
                Username
              </Text>
              <TextField.Root onChange={handleTextFieldChange("username")} style={{alignSelf: "stretch"}} size="2" variant="classic" />
              {validAuthDetails.username_valid ? null : <Text size="1" weight="regular" color="red">Input a valid username.</Text>}
            </Flex>
            <Flex className="password-field" display="flex" direction="column" align="start" gap="1" flexGrow="1">
              <Text size="2" weight="medium">
                Password
              </Text>
              <TextField.Root onChange={handleTextFieldChange("password")} style={{alignSelf: "stretch"}} size="2" variant="classic" />
              {validAuthDetails.password_valid ? null : <Text size="1" weight="regular" color="red">Input a valid password.</Text>}
            </Flex>
          </Flex>
        </Flex>
      </BrowseIntegrateCard.AuthDialog>
      <BrowseIntegrateCard.DetailDialog onSave={handleSave} onActivate={handleActivate}>
        <BrowseIntegrateCard.DetailDialogSection name="Locations" subtitle="Select which Unifi Site should be associated with each of your locations.">
          <Flex className="frame-528173481" display="flex" direction="column" align="end" gap="4" flexGrow="1">
            {payloadTwo.map((_, index) => (
              <Flex key={index} className="frame-528173433" style={{alignSelf: "stretch"}} display="flex" justify="center" align="end" gap="2">
                <Flex className="frame-528173429" display="flex" direction="column" align="start" gap="4" flexGrow="1" maxWidth="300px">
                  {index === 0 ? <Text size="1" weight="medium">Your locations</Text> : null}
                  <Select.Root size="2" onValueChange={handleDropdownFieldChange(index, "location")}>
                    <Select.Trigger style={{display: "flex", direction: "column", alignSelf: "stretch", maxWidth: "300px"}} variant="surface" placeholder="Select..." />
                    <Select.Content>
                      <Select.Group>
                        {initialItems.map((item) => (
                          <Select.Item key={item.value} value={item.value}>
                            {item.label}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Flex>
                <Flex display="flex" width="var(--space-6)" height="var(--space-6)" justify="center" align="center">
                  <IconButton size="2" variant="ghost" color="gray">
                    <ArrowRightIcon width="16" height="16" />
                  </IconButton>
                </Flex>
                <Flex className="frame-528173431" display="flex" direction="column" align="start" gap="4" flexGrow="1" maxWidth="300px">
                  {index === 0 ? <Text size="1" weight="medium">Unifi Site</Text> : null}
                  <Select.Root size="2" onValueChange={handleDropdownFieldChange(index, "site")}>
                    <Select.Trigger style={{display: "flex", direction: "column", alignSelf: "stretch", maxWidth: "300px"}} variant="surface" placeholder="Select..." />
                    <Select.Content>
                      <Select.Group>
                        {unifiSites?.map((item) => (
                          <Select.Item key={item.name} value={item.name}>
                            {item.desc}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </BrowseIntegrateCard.DetailDialogSection>
      </BrowseIntegrateCard.DetailDialog>
    </BrowseIntegrateCard.Root>
  );
}