import * as React from "react";
import { Flex, Text, Card, Switch, TextField, Button, Separator, Badge } from '@radix-ui/themes';
import { ToastAlert } from "components";
import { ReactComponent as LiveWirelessLANIcon} from "../../assets/icons/live_wlan.svg";
import { ReactComponent as OfflineWirelessLANIcon} from "../../assets/icons/offline_wlan.svg";
import { useGetWirelessLAN, useUpdateWirelessLAN } from "hooks";
import { useProfileContext } from "context";
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query'; // Import useQueryClient

const Networks = ( ) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userProfile } = useProfileContext();
  const organisationID = userProfile.organisation_id;
  
  // Grabbing the WLAN Data using the useGetWirelessLAN hook
  const { data: wirelessLanData } = useGetWirelessLAN(organisationID);

  // Handles dirty changes in the Networks Tab
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  // Call the hook at the component level, and get the function to execute the mutation
  const updateWirelessLAN = useUpdateWirelessLAN();

  // Creates the state that tracks changes in the network name, password.
  // Also creates the state that checks if the passworld field is visible, and if the save changes button is enabled or disabled.
  const [networkPageState, setNetworkPageState] = React.useState([]);
  const [interfaceState, setInterfaceState] = React.useState([]);

  React.useEffect(() => {
    if (wirelessLanData?.data) {
      const initialNetworkPageState = (Array.isArray(networkPageState) && networkPageState.length === 0) ?
        wirelessLanData?.data.map((entry) => ({id: entry._id, enabled: entry.enabled})) : 
        wirelessLanData?.data.map((_, index) => (networkPageState[index]));

      const initialInterfaceState = (Array.isArray(interfaceState) && interfaceState.length === 0) ? 
        wirelessLanData?.data.map((entry) => ({id: entry._id, disabled: true, textfield_type: "password"})) : 
        wirelessLanData?.data.map((entry, index) => ({id: entry._id, disabled: interfaceState[index].disabled, textfield_type: "password"}))
        
      setNetworkPageState(initialNetworkPageState);
      setInterfaceState(initialInterfaceState);
      setIsLoading(false);
    }
  }, [wirelessLanData]);
 
  // Creates the event handlers that will be used to update the network name and password
  const handleChanges = (event, id) => {
    // Sets the state of the textfield type to "text" if the password field is focused, and "password" if the password field is blurred.
    setInterfaceState(prevInterfaceState =>
      prevInterfaceState.map(item => {
        if (item.id === id) {
          let updatedItem = { ...item };
          if (event.type === "focus") {
            updatedItem.textfield_type = "text";
          } else if (event.type === "blur") {
            updatedItem.textfield_type = "password";
          } else if (event.type === "change") {
            updatedItem.disabled = false;
          }
          return updatedItem;
        }
        return item;
      })
    );

    if (event.type === "change") {
      // Sets the state of the "Save Changes" button to be enabled
      setInterfaceState(prevInterfaceState => prevInterfaceState.map(item =>
        item.id === id ? { ...item, disabled: false } : item
      ));

      // Sets the state of the network page name and password 
      setNetworkPageState(prevNetworkPageState =>
        prevNetworkPageState.map(item =>
          item.id === id ? { ...item, [event.target.name]: event.target.value } : item
        )
      );
      setIsDirty(true);
    }
  };

  // Creates the event handlers that will be used to send the enabled status to the backend.
  const sendPayload = async (checkedOrEvent, id) => {
    if (typeof checkedOrEvent === "boolean") {
      setNetworkPageState(prevNetworkPageState =>
        prevNetworkPageState.map(item =>
          item.id === id ? { ...item, enabled: checkedOrEvent } : item
        )
      );
  
      await updateWirelessLAN.mutateAsync({
        organisationID,
        data: {
          wlan_unifi_id: id,
          payload: {
            enabled: checkedOrEvent
          }
        }
      });
  
      queryClient.invalidateQueries(['wireless-lan', organisationID]);
  
    } else if (typeof checkedOrEvent === "object") {
      const payload = networkPageState.find(item => item.id === id);
      console.log("Payload", payload);
      await updateWirelessLAN.mutateAsync({
        organisationID,
        data: {
          wlan_unifi_id: id,
          payload
        }
      });
  
      queryClient.invalidateQueries(['wireless-lan', organisationID]);
    }

    setNetworkPageState(prevNetworkPageState =>
      prevNetworkPageState.map(item => item.id === id ? { id } : item)
    );
    
    setInterfaceState(prevInterfaceState =>
      prevInterfaceState.map(item => item.id === id ? { ...item, disabled: true } : item)
    );
  };

  React.useEffect(() => {
    if (interfaceState.every(item => item.disabled === true)) {
      setIsDirty(false);
    }
  }, [interfaceState]);

  return (
    <React.Fragment>
      <Flex className="main-content-container" style={{alignItems: 'flex-start', justifyContent: 'center',
        flex: '1 0 0', alignSelf: 'stretch', overflow: 'scroll', maxHeight: 'calc(100vh - 184px)'}}>
        <Flex direction="column" gap="5" style={{maxWidth: "496px", alignItems: "flex-start", flex: "1 0 0", 
          overflow: 'auto'}}>
            {wirelessLanData?.data.map((entry, index) => (
              entry?.x_passphrase === null ? (
                <React.Fragment key={index}>
                  <Card style={{display: 'flex', padding: 'var(--space-4)', alignSelf: 'stretch', 
                    flexDirection: 'column'}}>
                    <Flex className="frame-528172570" style={{alignSelf: "stretch"}} direction="column" align="start" gap="5">
                      <Flex className="frame-528172582" style={{alignSelf: "stretch"}} direction="column" align="start" gap="2">
                        <Flex className="frame-528172580" style={{alignSelf: "stretch"}} justify="between" align="center">
                          <Flex className="frame-528172775" display="flex" align="center" gap="2">
                            {entry?.enabled === true ? <LiveWirelessLANIcon style={{width: "var(--space-2)", height: "var(--space-2)"}} /> : <OfflineWirelessLANIcon style={{width: "var(--space-2)", height: "var(--space-2)"}} />}
                            <Text size="3" weight="medium">{entry?.type}</Text>
                            <Badge size="1" variant="soft" color="gray">Portal</Badge>
                          </Flex>
                          <Switch size="1" variant="classic" 
                            defaultChecked={entry?.enabled} 
                            onCheckedChange={(checked) => sendPayload(checked, entry._id)}
                          />
                        </Flex>
                        <Text style={{alignSelf: "stretch", color: "var(--gray-11)"}} size="1" weight="regular">
                          {entry?.description}
                        </Text>
                      </Flex>
                      <Flex className="frame-528172771" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" g="5">
                        <Flex className="frame-528172774" style={{alignSelf: "stretch"}} display="flex" direction="column" align="end" gap="5">
                          <label className="text-field" style={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "var(--space-1)", alignSelf: "stretch"}}>    
                            <Text size="2" weight="medium">
                              Network Name
                            </Text>
                            <Flex gap="4" style={{alignSelf: "stretch", alignItems: 'flex-end', justifyContent: 'center'}}>
                              <TextField.Root
                                name="name"
                                style={{ width: "50%", alignSelf: "stretch" }}
                                defaultValue={entry.name}
                                placeholder="Enter network name"
                                onChange={(event) => handleChanges(event, entry._id)}
                                size="2"
                                disabled={!entry.enabled}
                              />
                              <Button size="2" variant="surface" color="gray" 
                                onClick={() => navigate("/wifi/guest-portal")}
                                style={{width: '50%', cursor: 'pointer'}}>
                                  Edit Portal Design
                              </Button>
                            </Flex>  
                          </label>
                          <Button size="2" variant="surface" 
                            disabled={interfaceState[index]?.disabled} 
                            onClick={(event) => sendPayload(event, entry._id)}
                            style={{width: '100%'}}>
                            Save Changes
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Card>
                </React.Fragment>
              ) : (
                <React.Fragment key={index}>
                  <Card style={{display: 'flex', padding: 'var(--space-4)', alignSelf: 'stretch', 
                    flexDirection: 'column'}}>
                    <Flex className="frame-528172570" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="4">
                      <Flex className="frame-528172582" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="2">
                        <Flex className="frame-528172580" style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
                          <Flex className="frame-528172775" display="flex" align="center" gap="2">
                            {entry?.enabled === true ? <LiveWirelessLANIcon style={{width: "var(--space-2)", height: "var(--space-2)"}} /> : <OfflineWirelessLANIcon style={{width: "var(--space-2)", height: "var(--space-2)"}} />} 
                            <Text size="3" weight="medium">{entry?.type}</Text>
                            {entry?.type === "Work Wi-Fi Network" && 
                              <Badge size="1" variant="soft" color="gray">Portal</Badge>
                            }
                          </Flex>
                          <Switch size="1" variant="classic" defaultChecked={entry?.enabled} onCheckedChange={(checked) => sendPayload(checked, entry._id)}/>
                        </Flex>
                        <Text style={{alignSelf: "stretch", color: "var(--gray-11)"}} size="1" weight="regular">
                          {entry?.description}
                        </Text>
                      </Flex>
                      <Flex className="frame-528172771" style={{alignSelf: "stretch"}} display="flex" direction="column" align="stretch" gap="4">
                        <Flex className="frame-528172863" gap="4">
                          <label className="text-field" style={{flexDirection: "column", gap: "var(--space-1)", width: "50%"}}>    
                            <Text size="2" weight="medium">
                              Network Name
                            </Text>
                            <TextField.Root
                              name="name"
                              style={{
                                alignSelf: "stretch",
                              }}
                              defaultValue={entry.name}
                              placeholder="Enter network name"
                              onChange={(event) => handleChanges(event, entry._id)}
                              size="2"
                              disabled={!entry.enabled}
                            />  
                          </label>
                          <label className="text-field" style={{flexDirection: "column", gap: "var(--space-1)", width: "50%"}}>    
                            <Text size="2" weight="medium">
                              Password
                            </Text>
                            <TextField.Root
                              name="x_passphrase"
                              style={{
                                alignSelf: "stretch",
                              }}
                              type={interfaceState[index]?.textfield_type}
                              onFocus={(event) => handleChanges(event, entry._id)}
                              onBlur={(event) => handleChanges(event, entry._id)}
                              defaultValue={entry?.x_passphrase}
                              placeholder="Enter password"
                              onChange={(event) => handleChanges(event, entry._id)}
                              size="2"
                              disabled={!entry.enabled}
                            />
                          </label>
                        </Flex>
                        <Flex className="frame-528172774" style={{alignSelf: "stretch"}} display="flex" direction="column" align="end" gap="4">
                          <Button size="2" variant="surface" 
                            disabled={interfaceState[index]?.disabled} 
                            onClick={(event) => sendPayload(event, entry._id)}
                            style={{width: '100%'}}>
                            Save Changes
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Card>
                </React.Fragment>
              )
            ))
            }
        </Flex>
      </Flex>
      <ToastAlert/>
    </React.Fragment>
  );
}

export default Networks;