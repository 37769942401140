import { Text, Flex } from "@radix-ui/themes";

const NoResults = () => {
  return (
    <Flex 
      p="5" 
      direction="column" 
      gap="5" 
      style={{
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderRadius: '8px',
        border: '1px dashed var(--gray-a6)',
        height: "calc(100vh - 240px)",
        minHeight: 0,
        position: 'relative',
        backgroundColor: 'var(--color-background)',
        marginTop: '-24px'
      }}
    >
      <Flex direction="column" gap="2" style={{alignItems: 'center'}}>
        <Text size="5" weight="medium">
          No Results
        </Text>
        <Text size="2" weight="regular" color="gray" style={{width:'296px', textAlign:'center'}}>
          Adjust your filters to view different results.
        </Text>
      </Flex>
    </Flex>
  );
};

export default NoResults;