import { useInfiniteQuery } from '@tanstack/react-query';
import { attemptGetCustomers } from '../../api/UsersApi';

export const useGetCustomers = (filters) => {
    return useInfiniteQuery({
        queryKey: ['customers', JSON.stringify(filters)],
        queryFn: async ({ pageParam = 1 }) => {
            const response = await attemptGetCustomers({
                ...filters,
                page: pageParam
            });
            
            // Return both the API response and the current page number
            return {
                ...response.data,
                currentPage: pageParam
            };
        },
        // This determines if there should be a next page
        getNextPageParam: (lastPage) => {
            // If we received data in the last request, get next page
            if (lastPage.data && lastPage.data.length > 0) {
                return lastPage.currentPage + 1;
            }
            return undefined; // No more pages
        },
        enabled: !!filters,
        keepPreviousData: true
    });
};