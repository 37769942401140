import * as React from 'react';
import { Flex, IconButton, } from '@radix-ui/themes';

export function LayoutButton({ flag, state, onClick, children }) {

  return (
    state === flag ? (
      <IconButton onClick={() => onClick(flag)} className="sidebar-icon" size="3" variant="solid" style={{cursor: 'pointer'}}>
        {children}
      </IconButton>
    ) : (
      <Flex className="sidebar-icon-container" width="40px" height="40px" display="flex" align="center" justify="center">
        <IconButton onClick={() => onClick(flag)} className="sidebar-icon" size="3" variant="ghost" style={{cursor: 'pointer'}}>
          {React.isValidElement(children) ? 
            React.cloneElement(children, { color: "gray", ...children.props}) : 
            children
          }
        </IconButton>
      </Flex>
    )
  );
}
