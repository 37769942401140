import * as React from "react";
import { Separator } from "components/separator/Separator";
import {
  Text,
  Flex,
  Button,
  Checkbox,
  CheckboxGroup,
  TextField,
  Popover,
  IconButton,
} from "@radix-ui/themes";
import {
  CaretSortIcon,
  Cross1Icon,
  MagnifyingGlassIcon,
} from "@radix-ui/react-icons";

export function LocationMultiSelect({
  width=null,
  noShowBorder,
  options,
  onValueChange,
  defaultValue,
}) {
  const [selectedValues, setSelectedValues] = React.useState(defaultValue);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [dateDisplay, setDateDisplay] = React.useState("All locations");
  const [checkBoxBool, setCheckBoxBool] = React.useState();
  const [topCheckBoxBool, setTopCheckBoxBool] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");

  React.useEffect(() => {
    setSelectedValues(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    if (options && options.length > 0) {
      setCheckBoxBool(new Array(options.length).fill(true));
    }
  }, [options]);

  const updateDisplayAndCheckboxes = (newSelectedValues) => {
    setDateDisplay(
      newSelectedValues.length === 0
        ? "Select locations"
        : newSelectedValues.length === options.length
        ? "All locations"
        : `${newSelectedValues.length} location${newSelectedValues.length > 1 ? 's' : ''}`
    );
    setCheckBoxBool(
      options.map((option) => newSelectedValues.includes(option.value))
    );
    setTopCheckBoxBool(newSelectedValues.length === options.length);
  };

  const handleClear = () => {
    setSelectedValues([]);
    onValueChange([]);
    updateDisplayAndCheckboxes([]);
  };

  const toggleAll = () => {
    if (selectedValues.length === options.length) {
      handleClear();
    } else {
      const allValues = options.map((option) => option.value);
      setSelectedValues(allValues);
      onValueChange(allValues);
      updateDisplayAndCheckboxes(allValues);
    }
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger asChild>
        <Button
          style={{
            width: width,
            marginLeft: noShowBorder ? "3px" : null
          }}
          size="2"
          variant={noShowBorder ? "ghost" : "soft"}
          color="gray"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <Text color="gray" weight="medium">
            {dateDisplay}
          </Text>
          {width ? <Flex flexGrow="1" /> : null}
          <CaretSortIcon style={{ color: "var(--gray-11)" }} />
        </Button>
      </Popover.Trigger>

      <Popover.Content
        style={{
          display: "flex",
          width: "291px",
          flexDirection: "column",
          alignItems: "start",
          padding: "0",
        }}
        maxWidth="480px"
      >
        <Flex
          className="slot-container"
          style={{ alignSelf: "stretch" }}
          display="flex"
          direction="column"
          align="start"
        >
          {/* Search Row */}
          <Flex
            className="search-row"
            style={{
              alignSelf: "stretch",
              padding: "var(--space-2) 8px var(--space-2) 20px",
            }}
            justify="start"
            align="center"
            gap="2"
          >
            <Checkbox
              size="2"
              variant="surface"
              checked={topCheckBoxBool}
              onCheckedChange={toggleAll}
            />
            <TextField.Root
              style={{ flexGrow: "1" }}
              size="2"
              variant="classic"
              placeholder="Search"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            >
              <TextField.Slot>
                <MagnifyingGlassIcon height="16" width="16" />
              </TextField.Slot>
              
                <TextField.Slot>
                  <IconButton
                    size="1"
                    variant="ghost"
                    color="gray"
                    onClick={clearSearchQuery}
                  >
                    <Cross1Icon height="16" width="16" />
                  </IconButton>
                </TextField.Slot>
            </TextField.Root>
          </Flex>
          <Separator color="gray" size="1" />
          {/* Options List */}
          <Flex
            className="location-list-container"
            style={{ alignSelf: "stretch" }}
            display="flex"
            p="2"
            direction="column"
            justify="center"
            align="start"
            gap="2"
          >
            <CheckboxGroup.Root
              size="2"
              variant="surface"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                alignSelf: "stretch",
                gap: "0px",
              }}
              value={selectedValues}
              onValueChange={(newValues) => {
                setSelectedValues(newValues);
                onValueChange(newValues);
                updateDisplayAndCheckboxes(newValues);
              }}
            >
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <Flex
                    key={option.value}
                    display="flex"
                    style={{ alignSelf: "stretch" }}
                    height="var(--space-6)"
                    px="3"
                    align="center"
                    gap="2"
                  >
                    <CheckboxGroup.Item value={option.value}>
                      {option.label}
                    </CheckboxGroup.Item>
                  </Flex>
                ))
              ) : (
                <Text px="3">No Locations Found</Text>
              )}
            </CheckboxGroup.Root>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}
