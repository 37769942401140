import React, { useState } from 'react';
import { Flex, Text, Card, Button, Badge, IconButton, DropdownMenu, Separator, TextField } from '@radix-ui/themes';
import { PlusIcon, DotsHorizontalIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { ReactComponent as BWifiIcon } from '../../assets/icons/favicon-dark.svg';
import { useLocationContext } from 'context';


const OrganisationLocations = () => {
  
  // Fetching all the locations info for the organisation
  const { locationsData } = useLocationContext();
  
  // For the dropdown menu
  const [dropdownStates, setDropdownStates] = useState({});
  const handleShowDropdown = (locationId) => () => {
    setDropdownStates(prevStates => ({
      ...prevStates,
      [locationId]: !prevStates[locationId]
    }));
  };

  // For the Save Changes button when the user makes changes
  const [ isDirty, setIsDirty ] = useState(false);
  
  return (
    <React.Fragment>
      <Flex direction="column" gap="5" height="100%" style={{alignSelf: 'stretch', flex: '1 0 0'}}>
          {/* New Locations Section */}
          <Flex gap="2" style={{justifyContent: 'flex-end', alignItems: 'center', alignSelf: 'stretch'}}>
            <Button size="2" variant="soft" px="3" style={{cursor: 'pointer'}}>
              <PlusIcon width="16" height="16"/>
              <Text size="2" weight="medium">New location</Text>
            </Button>
          </Flex>
          {/* Location Details Section */}
          { locationsData && locationsData.map((location) => (
            <Card p="4" key={location.id} style={{display: 'flex', flexDirection: 'column', 
              alignItems: 'flex-start', alignSelf: 'stretch', gap: 'var(--space-4)',}}>
              {/* Locations Header Section */}
              <Flex style={{alignSelf: 'stretch', alignItems: 'center', justifyContent: 'space-between'}}>
                {/* Location Name & Address */}
                <Flex direction="column" gap="1" style={{alignSelf: 'stretch', alignItems: 'flex-start', width: '30%'}}>
                  <Text size="4" weight="bold">{location.name}</Text>
                  <Text size="1" weight="medium" color="gray" style={{textOverflow: 'ellipsis', overflow: 'hidden', 
                    whiteSpace: 'nowrap', width: '100%', paddingRight: '4px'}}>
                    {location.address}
                  </Text>
                </Flex>
                {/* Information Badges */}
                <Flex style={{alignSelf: 'stretch', alignItems: 'center', justifyContent: 'space-between', width: '70%' }}>
                  <Badge size="1" variant="soft" color="gray">4 Wi-Fi Access Points</Badge>
                  <Badge size="1" variant="soft" color="gray">13 integrations</Badge>
                  <Text size="1" weight="regular" color="gray">Restaurant</Text>
                  <Text size="1" weight="regular" color="gray">2 Managers</Text>
                  {/* Possible Actions */}
                  <Flex gap="4" style={{alignItems: 'center'}}>
                      {/* Ellipsis Icon for Deleting Location */}
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <IconButton size="1" variant="ghost" color="gray" style={{cursor: 'pointer'}}>
                            <DotsHorizontalIcon width='16' height='16' />
                          </IconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                          <DropdownMenu.Item style={{cursor: 'pointer'}} color='red'>Delete</DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                      {/* Arrow Icon for Editing Location Details */}
                      <IconButton size="1" variant="ghost" color="gray"
                        onClick={handleShowDropdown(location.id)}
                        style={{cursor: 'pointer'}}>
                        {dropdownStates[location.id]
                          ? <ChevronDownIcon width='16' height='16' />
                          : <ChevronUpIcon width='16' height='16' />
                        }
                      </IconButton>
                  </Flex>
                </Flex>
              </Flex>
              {/* Location Details Section */}
              {dropdownStates[location.id] && 
              (
              <Flex direction="column" gap="4" style={{alignSelf: 'stretch', alignItems: 'flex-start'}}>
                <Separator size="4" orientation="horizontal"/>
                {/* Location Details */}
                <Flex gap="4" style={{alignItems: 'flex-start', alignSelf:'stretch'}}>
                  <Flex direction="column" gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '40%'}}>
                    <Text size="2" weight="medium">Details</Text>
                    <Text size="1" weight="regular" color="gray">
                      Share your location name and address to enhance your integrations.
                    </Text>
                  </Flex>
                  <Flex direction="column" gap="4" style={{alignItems: 'flex-end', alignSelf: 'stretch', width: '60%', flex: '1 0 0'}}>
                    {/* Name and Type Fields */}
                    <Flex direction="row" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', justifyContent: 'center'}}>
                      {/* Name Field */}
                      <Flex direction="column" gap="1" style={{alignItems: 'center', alignSelf: 'stretch', width: '100%'}}>
                        <Text size="2" weight="medium" style={{width: '100%'}}>Name</Text>
                        <TextField.Root size="2" style={{width: '100%'}}
                          value={location.name}
                        />
                      </Flex>
                      {/* Type Select Field */}
                      <Flex direction="column" gap="1" style={{alignItems: 'center', alignSelf: 'stretch', width: '100%'}}>
                        <Text size="2" weight="medium" style={{width: '100%'}}>Type</Text>
                        <TextField.Root size="2"style={{width: '100%'}}/>
                      </Flex>
                    </Flex>
                    {/* Address Field */}
                    <Flex direction="column" gap="1" style={{alignItems: 'center', alignSelf: 'stretch'}}>
                      <Text size="2" weight="medium" style={{width: '100%'}}>Address</Text>
                      <TextField.Root size="2" style={{width: '100%'}}
                        value={location.address}
                      />
                    </Flex>
                    {/* Save Changes Button */}
                    <Button size="2" variant='surface' style={{cursor: 'pointer'}}
                      disabled={!isDirty}>
                      Save Changes
                    </Button>
                  </Flex>
                </Flex>
                <Separator size="4" orientation="horizontal"/>
                {/* Integration Details */}
                <Flex gap="4" style={{alignItems: 'flex-start', alignSelf:'stretch', height: '64px'}}>
                  <Flex direction="column" gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '40%'}}>
                    <Text size="2" weight="medium">Integrations</Text>
                  </Flex>
                  <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '60%', flex: '1 0 0'}}>
                    {/* Active Integrations */}
                    <Flex gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                      <Flex gap="2" style={{alignItems: 'center', alignSelf: 'stretch'}}>
                        <BWifiIcon width="18" height="18" />
                        <Text size="1" weight="medium">b Connect Wi-Fi</Text>
                      </Flex>
                      <Flex gap="2" style={{alignItems: 'center', alignSelf: 'stretch'}}>
                        <BWifiIcon width="18" height="18" />
                        <Text size="1" weight="medium">Square</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              )}
            </Card>
          ))}
      </Flex>
    </React.Fragment>
  );
}

export default OrganisationLocations;
