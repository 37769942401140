import React from 'react';
import { Flex, Text, IconButton, Select, Button } from '@radix-ui/themes';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { ActiveIntegrateCard } from 'components';
import { useLocationContext } from 'context';

export function ActiveUnifiIntegrations() {
  const { initialItems } = useLocationContext();

  return (
    <ActiveIntegrateCard.Root name="Unifi" subtitle="Wi-Fi" image_url="unifi.png" option={{text: "Deactivate", color: "red", onClick: () => console.log("Deactivate")}} >
      <ActiveIntegrateCard.Section name="Locations" subtitle="Select which Unifi Site should be associated with each of your locations.">
        <Flex className="frame-528173481" display="flex" direction="column" align="end" gap="4" flexGrow="1">
          <Flex className="frame-528173433" style={{alignSelf: "stretch"}} display="flex" justify="center" align="end" gap="2">
            <Flex className="frame-528173429" display="flex" direction="column" align="start" gap="4" flexGrow="1">
              <Text size="1" weight="medium">
                Your locations
              </Text>
              <Select.Root size="2" defaultValue={initialItems[0]?.value}>
                <Select.Trigger style={{display: "flex", direction: "column", alignSelf: "stretch"}} variant="surface"/>
                <Select.Content>
                  <Select.Group>
                    {initialItems.map((item) => (
                      <Select.Item key={item.value} value={item.value}>
                        {item.label}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Flex>
            <Flex display="flex" width="var(--space-6)" height="var(--space-6)" justify="center" align="center">
              <IconButton size="2" variant="ghost" color="gray">
                <ArrowRightIcon width="16" height="16" />
              </IconButton>
            </Flex>
            <Flex className="frame-528173431" display="flex" direction="column" align="start" gap="4" flexGrow="1">
              <Text size="1" weight="medium">
                Unifi Site
              </Text>
              <Select.Root size="2" defaultValue={initialItems[0]?.value}>
                <Select.Trigger style={{display: "flex", direction: "column", alignSelf: "stretch"}} variant="surface"/>
                <Select.Content>
                  <Select.Group>
                    {initialItems.map((item) => (
                      <Select.Item key={item.value} value={item.value}>
                        {item.label}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Flex>
          </Flex>
          <Flex className="frame-528173434" style={{alignSelf: "stretch"}} display="flex" justify="center" align="end" gap="2">
            <Flex className="frame-528173429" display="flex" direction="column" align="start" gap="4" flexGrow="1">
              <Select.Root size="2">
                <Select.Trigger style={{display: "flex", direction: "column", alignSelf: "stretch"}} placeholder="Select" variant="surface"/>
                <Select.Content>
                  <Select.Group>
                    {initialItems.map((item) => (
                      <Select.Item key={item.value} value={item.value}>
                        {item.label}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Flex>
            <Flex display="flex" width="var(--space-6)" height="var(--space-6)" justify="center" align="center">
              <IconButton size="2" variant="ghost" color="gray">
                <ArrowRightIcon width="16" height="16" />
              </IconButton>
            </Flex>
            <Flex className="frame-528173431" display="flex" direction="column" align="start" gap="4" flexGrow="1">
              <Select.Root size="2">
                <Select.Trigger style={{display: "flex", direction: "column", alignSelf: "stretch"}} placeholder="Select" variant="surface"/>
                <Select.Content>
                  <Select.Group>
                    {initialItems.map((item) => (
                      <Select.Item key={item.value} value={item.value}>
                        {item.label}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Flex>
          </Flex>
          <Button size="2" variant="surface" color="accent">
            Save Changes
          </Button>
        </Flex>
      </ActiveIntegrateCard.Section>
    </ActiveIntegrateCard.Root>
  );
}