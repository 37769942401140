import React from 'react';
import { ActiveIntegrateCard } from 'components';
import { StackedStatusBar } from 'components/charts/StackedStatusBar';

export function ActiveBconnectIntegrations() {
  return (
    <ActiveIntegrateCard.Root name="b connect Wi-Fi" subtitle="Wi-Fi" image_url="favicon-light.png" 
      option={{text: "Contact Support", onClick: () => {
      const supportEmail = 'support@b-connect.co.uk';
      const subject = 'Support Request';
      const body = 'Hello, I need help with...';
      const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }}}>
      <ActiveIntegrateCard.Section name="Locations" subtitle="If you encounter any issues or need another access point, please reach out to our support team.">
        <StackedStatusBar />
      </ActiveIntegrateCard.Section>
    </ActiveIntegrateCard.Root>
  );
}