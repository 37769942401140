import React, { createContext, useContext } from 'react';
import { Flex, Card, Text, Button, Badge, Dialog, Separator, Inset, AlertDialog, Box } from '@radix-ui/themes';

const BrowseIntegrateCardContext = createContext(null);

function Root({ name, subtitle, image_url, status, openDialog, handleOpenChange, children}) {
  // Put your image inside src/assets/images!
  // const [openDialog, setOpenDialog] = useState({
  //   auth: false,
  //   detail: false
  // });

  // Convert children to an array for easy manipulation
  const childrenArray = React.Children.toArray(children);

  // Extract the first and second dialog
  const [firstDialog, secondDialog] = childrenArray;

  return (
    <BrowseIntegrateCardContext.Provider value={{name, subtitle, image_url, status, openDialog, handleOpenChange, secondDialog}}>
      <Card style={{display: "flex", height: "94px", minWidth: "455px", padding: "var(--space-4)", justifyContent: "space-between", alignItems: "center"}}>
        <Flex className="frame-528173423" display="flex" align="center" gap="4">
          {status === "request" ? (
            <Flex style={{borderRadius: "var(--space-1)", border: "1px dashed var(--gray-a6)", background: "var(--gray-a2)"}} display="flex" width="40px" height="40px" p="2" justify="center" align="center" gap="2"/>
          ) : (
            <Flex className="frame-528173424" style={{borderRadius: "var(--space-1)", background: "var(--gray-12)"}} display="flex" height="40px" p="2" justify="center" align="center" gap="2">
              <img src={require(`../../assets/images/${image_url}`)} alt="icon" style={{width: "24px", height: "24px", borderRadius: "var(--space-1)", background: "lightgray 50% / cover no-repeat"}} />
            </Flex>
          )}
          <Flex className="frame-528173402" display="flex" direction="column" justify="center" align="start" gap="1">
            <Text size="4" weight="bold">
              {name}
            </Text>
            <Text style={{color: "var(--gray-a11)"}} size="1" weight="regular">
              {subtitle}
            </Text>
          </Flex>
        </Flex>
        {firstDialog}
      </Card>
    </BrowseIntegrateCardContext.Provider>
  );
}

function AuthDialog({ dialog_description, children, onCancel, leftButton}) {
  const { name, status, openDialog, handleOpenChange, secondDialog } = useContext(BrowseIntegrateCardContext);
  console.log("status", status);
  console.log("name", name);
  let statusButton;
  // const buttonProps = {
  //   size: "2",
  //   variant: "soft",
  //   color: "gray",
  //   disabled: status === "coming-soon"
  // };

  if (status === "active") {
    statusButton = <Badge size="1" variant="soft" color="green">Active</Badge>;
  } else if (status === "contact-sales") {
    statusButton = <Button onClick={() => {
      const supportEmail = 'support@b-connect.co.uk';
      const subject = 'Support Request';
      const body = 'Hello, I need help with...';
      const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }} size="2" variant="soft" color="gray">Contact Sales</Button>
  } else if (status === "inactive") {
    statusButton = <Button size="2" variant="soft" color="gray">Activate</Button>
  } else if (status === "request") {
    statusButton = <Button onClick={() => {
      const supportEmail = 'support@b-connect.co.uk';
      const subject = 'Support Request';
      const body = 'Hello, I need help with...';
      const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }} size="2" variant="soft" color="gray">Request</Button>
  } else if (status === "coming-soon") {
    statusButton = <Button size="2" variant="soft" color="gray" disabled
    style={{ 
      cursor: 'not-allowed',
      userSelect: 'none', 
    }}>Coming soon</Button>;
  }
  return (
    <Dialog.Root open={openDialog.auth} onOpenChange={(isOpen) => handleOpenChange("auth", isOpen)}>
      <Dialog.Trigger asChild>
        {statusButton}
      </Dialog.Trigger>
      <Dialog.Content  maxWidth="600px" maxHeight="690px" align="center">
        <Dialog.Title>Activate {name.charAt(0).toUpperCase() + name.slice(1)}</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          {dialog_description}
        </Dialog.Description>
        {children}
          <Flex 
            style={{
              alignSelf: "stretch",
              width: "100%"
            }} 
            display="flex" 
            justify="between" 
            align="center" 
            mt="4"
          >
            {leftButton}
            <Flex display="flex" gap="3">
              <Dialog.Close>
                <Button onClick={onCancel} size="2" variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              {secondDialog}
            </Flex>
          </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function DetailDialog({ children, onSave, onActivate }) {
  const { name, subtitle, image_url, openDialog, handleOpenChange } = useContext(BrowseIntegrateCardContext);

  return (
    <AlertDialog.Root open={openDialog.detail}>
      <AlertDialog.Action>
        <Button onClick={onSave} size="2" variant="soft" color="accent">Save and continue</Button>
      </AlertDialog.Action>
      <AlertDialog.Content maxWidth="1000px" align="center">
        <AlertDialog.Title hidden={true}/>
        <AlertDialog.Description hidden={true}/>
        <Inset>
          <Flex style={{alignSelf: "stretch"}} display="flex" align="start" direction="column" p="4" gap="4" maxWidth="1000px">
            <Flex className="frame-528173404" style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
              <Flex className="frame-528173423" display="flex" align="center" gap="4">
                <Flex className="frame-528173424" style={{borderRadius: "var(--space-1)", background: "var(--gray-12)"}} display="flex" height="40px" p="2" justify="center" align="center" gap="2">
                  <img src={require(`../../assets/images/${image_url}`)} alt="icon" style={{width: "24px", height: "24px", borderRadius: "var(--space-1)", background: "lightgray 50% / cover no-repeat"}} />
                </Flex>
                <Flex className="frame-528173402" display="flex" direction="column" justify="center" align="start" gap="1">
                  <Text size="4" weight="bold">
                    {name}
                  </Text>
                  <Text style={{color: "var(--gray-a11)"}} size="1" weight="regular">
                    {subtitle}
                  </Text>
                </Flex>
              </Flex>
              <Flex display="flex" align="center" gap="4">
                <AlertDialog.Cancel>
                  <Button size="2" variant="soft" onClick={() => handleOpenChange("detail", false)} color="gray">Cancel</Button>
                </AlertDialog.Cancel>
                <AlertDialog.Action>
                  <Button size="2" variant="soft" onClick={onActivate}>Save and finish</Button>
                </AlertDialog.Action>
              </Flex>
            </Flex>
            {children}
          </Flex>
        </Inset>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

function DetailDialogSection({ name, subtitle, children }) {
  return (
    <React.Fragment>
      <Separator orientation="horizontal" size="4"/>
      <Flex className="frame-528173428" style={{alignSelf: "stretch"}} display="flex" align="start" gap="5">
        <Flex className="frame-528173428" style={{alignSelf: "stretch"}} display="flex" width="296px" direction="column" align="start" gap="2">
          <Text size="2" weight="medium">
            {name}
          </Text>
          <Text size="1" weight="regular">
            {subtitle}
          </Text>
        </Flex>
        {children}
      </Flex>
    </React.Fragment>
  );
}

export const BrowseIntegrateCard = {
  Root,
  AuthDialog,
  DetailDialog,
  DetailDialogSection,
}

