import React from 'react';
import { Grid, Box } from '@radix-ui/themes';
import { BrowseUnifiIntegrations } from './unifi/BrowseUnifiIntegrations';
import { useProfileContext } from 'context';
import { BrowseMailchimpIntegration } from './mailchimp/BrowseMailchimpIntegration';
import { BrowseIntegrateCard } from 'components';

export default function BrowseIntegrations() {
  const { userProfile } = useProfileContext();

  const [openDialog, setOpenDialog] = React.useState({
    auth: false,
    detail: false
  });

  const handleOpenChange = (dialog, isOpen) => {
    setOpenDialog(prev => ({...prev, [dialog]: isOpen}));
  };

  return (
    <Grid columns="1fr 1fr" gap="5" rows="repeat(3, 94px)" width="100%" >
      <BrowseIntegrateCard.Root 
        name="b connect Wi-Fi" 
        subtitle="Wi-Fi" 
        image_url="favicon-light.png" 
        status="contact-sales"
        openDialog={openDialog}
        handleOpenChange={handleOpenChange}
      >
        <BrowseIntegrateCard.AuthDialog 
          dialog_description="Contact our sales team to activate b connect Wi-Fi integration"
          onCancel={() => handleOpenChange("auth", false)}
        />
      </BrowseIntegrateCard.Root>

      <BrowseUnifiIntegrations userProfile={userProfile}/>
      <BrowseMailchimpIntegration userProfile={userProfile}/>
{/* 
      <BrowseIntegrateCard.Root name="b connect Wi-Fi" subtitle="Wi-Fi" image_url="favicon-light.png" status="contact-sales" option={() => {
        const supportEmail = 'support@b-connect.co.uk';
        const subject = 'Support Request';
        const body = 'Hello, I need help with...';
        const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
      }}/> */}
      {/* <BrowseIntegrateCard.Root name="Cisco Meraki" subtitle="Wi-Fi" image_url="cisco.png" status="coming-soon" option={() => console.log("Coming soon")} /> */}
      {/* <BrowseIntegrateCard.Root name="Looking for a new integration?" subtitle="Submit your request, and receive reply soon." status="request" option={() => {
        const supportEmail = 'support@b-connect.co.uk';
        const subject = 'Support Request';
        const body = 'Hello, I need help with...';
        const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
      }}/> */}


      <BrowseIntegrateCard.Root 
        name="Cambium Networks" 
        subtitle="Wi-Fi" 
        image_url="cambium-networks.png" 
        status="coming-soon"
        openDialog={openDialog}
        handleOpenChange={handleOpenChange}
      >
        <BrowseIntegrateCard.AuthDialog 
          dialog_description="Cisco Meraki integration is coming soon"
          onCancel={() => handleOpenChange("auth", false)}
        />
      </BrowseIntegrateCard.Root>


      <BrowseIntegrateCard.Root 
        name="Cisco Meraki" 
        subtitle="Wi-Fi" 
        image_url="cisco.png" 
        status="coming-soon"
        openDialog={openDialog}
        handleOpenChange={handleOpenChange}
      >
        <BrowseIntegrateCard.AuthDialog 
          dialog_description="Cisco Meraki integration is coming soon"
          onCancel={() => handleOpenChange("auth", false)}
        />
      </BrowseIntegrateCard.Root>

      <BrowseIntegrateCard.Root 
        name="Aruba" 
        subtitle="Wi-Fi" 
        image_url="aruba.png" 
        status="coming-soon"
        openDialog={openDialog}
        handleOpenChange={handleOpenChange}
      >
        <BrowseIntegrateCard.AuthDialog 
          dialog_description="Cisco Meraki integration is coming soon"
          onCancel={() => handleOpenChange("auth", false)}
        />
      </BrowseIntegrateCard.Root>

      <BrowseIntegrateCard.Root 
        name="Horjar" 
        subtitle="CRM" 
        image_url="hotjar.png" 
        status="coming-soon"
        openDialog={openDialog}
        handleOpenChange={handleOpenChange}
      >
        <BrowseIntegrateCard.AuthDialog 
          dialog_description="Cisco Meraki integration is coming soon"
          onCancel={() => handleOpenChange("auth", false)}
        />
      </BrowseIntegrateCard.Root>

      <BrowseIntegrateCard.Root 
        name="Zapier" 
        subtitle="Integration automation" 
        image_url="zapier.png" 
        status="coming-soon"
        openDialog={openDialog}
        handleOpenChange={handleOpenChange}
      >
        <BrowseIntegrateCard.AuthDialog 
          dialog_description="Cisco Meraki integration is coming soon"
          onCancel={() => handleOpenChange("auth", false)}
        />
      </BrowseIntegrateCard.Root>


      <Box style={{ gridColumn: '1 / -1' }}>
  <BrowseIntegrateCard.Root 
    name="Looking for a new integration?" 
    subtitle="Submit your request, and receive reply soon." 
    status="request"
    openDialog={openDialog}
    handleOpenChange={handleOpenChange}
  >
    <BrowseIntegrateCard.AuthDialog 
      dialog_description="Submit your integration request"
      onCancel={() => handleOpenChange("auth", false)}
    />
  </BrowseIntegrateCard.Root>
  
</Box>

    </Grid>
  );
}